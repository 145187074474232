import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/Modal";
import "./styles.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { authActions } from "../../store/authSlice";

export default function TermsDialog() {
  const isOpen = useSelector((state) => state.auth.terms.isOpen);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  function handleOnConfirm() {
    dispatch(authActions.setTermsIsOpen(false));
  }
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen]);
  return (
    <Modal open={isOpen} className="modal modal_input w-[300px]">
      <div className="w-full pt-[200px] pb-[150px] flex items-center justify-center">
        <p>이용약관 내용 첨부 필요</p>
      </div>
      <div className="button-container">
        <button
          onClick={handleOnConfirm}
          className="common-button confirm-button-single"
        >
          {t("button.confirm")}
        </button>
      </div>
    </Modal>
  );
}
