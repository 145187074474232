import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getLanguage } from "./api/i18n";

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: async (options, url, payload, callback) => {
    try {
      const [lng, ns] = url.split("|");

      getLanguage({ lng, ns }).then(async (response) => {
        try {
          const localResponse = await fetch(
            `/locales/${lng}/translations.json`
          );
          if (localResponse.ok) {
            const localData = await localResponse.json();
            callback(null, {
              data: localData,
              status: 200,
            });
          } else {
            throw new Error("로컬 파일에서 데이터를 불러오지 못했습니다.");
          }
        } catch (localError) {
          callback(localError, null);
        }
        // if (response) {
        //   console.log("서버에서 언어 데이터 불러오기 성공");
        //   callback(null, {
        //     data: response,
        //     status: 200,
        //   });
        // } else {
        //   console.log("서버에서 언어 데이터 불러오기 실패");
        //   try {
        //     const localResponse = await fetch(
        //       `/locales/${lng}/translations.json`
        //     );
        //     if (localResponse.ok) {
        //       const localData = await localResponse.json();
        //       callback(null, {
        //         data: localData,
        //         status: 200,
        //       });
        //     } else {
        //       throw new Error("로컬 파일에서 데이터를 불러오지 못했습니다.");
        //     }
        //   } catch (localError) {
        //     callback(localError, null);
        //   }
        // }
      });
    } catch (e) {
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "ko",
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    backend: backendOptions,
  });

export default i18n;
