export default function ButtonTablePage({ children, ...props }) {
  return (
    <button
      className="w-[28px] h-[28px] bg-[#f1f2f6] rounded-[4px] flex items-center justify-center text-[13px] font-publicSans"
      {...props}
    >
      {children}
    </button>
  );
}
