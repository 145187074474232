import { useNavigate } from "react-router-dom";

export const errorCatch = (error) => {
  if (error.status === "401") {
    console.log("ghkrdls");
  }
  if (error.response) {
    // 서버가 응답했지만 401 또는 다른 상태 코드를 반환한 경우

    if (error.response.data.message != "") {
      alert(error.response.data.message);
    } else {
      alert(error.status);
    }
    console.error("서버 응답 데이터:", error.response.data);
    console.error("상태 코드:", error.response.status);
    console.error("헤더:", error.response.headers);
  } else if (error.request) {
    // 요청이 전송되었으나 응답이 없는 경우
    alert(error.request);
  } else {
    // 오류를 발생시킨 요청 설정
    alert(error.message);
  }
};
