import { api } from "./base";

export const getLanguage = async ({ lng, ns }) => {
  try {
    const response = await api.get(`customer/i18n/${lng}/${ns}.json`);
    if (response) {
      const responseData = response.data;
      return responseData;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
