import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import Input from "../components/auth/Input";
import AuthFormat from "../components/auth/AuthFormat";
import Terms from "../components/auth/Terms";

export default function AuthEmailPage() {
  const { t } = useTranslation();
  const inputIsValid = useSelector(
    (state) => state.auth.emailState.inputIsValid
  );
  const isLoading = useSelector((state) => state.common.isLoading);
  return (
    <AuthFormat>
      <div className="text-gray30 font-medium font-poppins pt-[16px] h-[101px]">
        <p className="text-gray30 font-medium">
          {t("login.header.description")}
        </p>
      </div>
      <div className="relative pt-[10px] mb-[50px]">
        <Input
          icon={
            <svg
              width="25"
              height="25"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 5H5C4.33696 5 3.70107 5.26339 3.23223 5.73223C2.76339 6.20107 2.5 6.83696 2.5 7.5V22.5C2.5 23.163 2.76339 23.7989 3.23223 24.2678C3.70107 24.7366 4.33696 25 5 25H25C25.663 25 26.2989 24.7366 26.7678 24.2678C27.2366 23.7989 27.5 23.163 27.5 22.5V7.5C27.5 6.83696 27.2366 6.20107 26.7678 5.73223C26.2989 5.26339 25.663 5 25 5ZM25 10.295L15 16.3975L5 10.295V7.5H25V10.295Z"
                fill={`${inputIsValid ? "#002A70" : "#868AA5"}`}
              />
            </svg>
          }
          placeholder={t("login.email.placeholder")}
          authStep={"email"}
        >
          <Terms />
        </Input>
        {inputIsValid && !isLoading && (
          <div className="absolute left-[20px] top-0 bg-white text-gray3c flex justify-center items-center w-[78px] h-[20px]">
            {t("login.email.label")}
          </div>
        )}
      </div>
    </AuthFormat>
  );
}
