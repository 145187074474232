import { api } from "./base";
import { getAccessToken } from "../store/token";
import { errorCatch } from "./common";
import axios from "axios";

export const getDevices = async ({ isTest }) => {
  try {
    const token = getAccessToken();
    // const stringPage = page.toString();
    // const stringSize = size.toString();
    // console.log("stringPage", stringPage);
    // console.log("stringSize", stringSize);
    const response = isTest
      ? await axios.get("/customer/devices", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      : await api.get("/customer/devices", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          //   params: {
          //     page: stringPage,
          //     size: stringSize,
          //   },
        });
    if (response.data) {
      const responseData = response.data;
      return responseData;
    } else {
      return false;
    }
  } catch (error) {
    console.log("기기 불러오는 중 오류발생", error);
    errorCatch(error);
    return false;
  }
};

export const getProducts = async ({ isTest }) => {
  try {
    const token = getAccessToken();
    const response = isTest
      ? await axios.get("/customer/devices/products", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      : await api.get("/customer/devices/products", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    if (response.data) {
      const responseData = response.data;
      return responseData;
    } else {
      return false;
    }
  } catch (error) {
    console.error("기기 모델 불러오는 중 오류 발생:", error);
    errorCatch(error);
    return false;
  }
};

export const registDevice = async ({
  prodName,
  regCode,
  serial,
  deviceState,
}) => {
  try {
    const token = getAccessToken();
    const requestBody = {
      prodName,
      regCode,
      serial,
      deviceState,
    };

    const response = await api.post("/customer/devices", requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { isPassed: true, errorCode: "204" };
  } catch (error) {
    errorCatch(error);
    console.log("error", error);
    return { isPassed: false, errorCode: error.status };
  }
};

export const getDeviceStates = async () => {
  try {
    const token = getAccessToken();
    const response = await api.get("/customer/devices/states", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      const responseData = response.data;
      console.log("responseData", responseData);
      return responseData;
    } else {
      return false;
    }
  } catch (error) {
    console.error("기기 상태 불러오는 중 오류 발생:", error);
    errorCatch(error);
    return false;
  }
};

export const patchDeviceState = async ({ deviceState, id }) => {
  try {
    const token = getAccessToken();
    const requestBody = {
      deviceState,
    };

    const response = await api.patch(`/customer/devices/${id}`, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("기기 상태 수정 중 오류 발생:", error);
    errorCatch(error);
  }
};
export const deleteDevice = async ({ id, isTest }) => {
  try {
    const token = getAccessToken();

    const response = isTest
      ? await axios.delete(`/customer/devices/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      : await api.delete(`/customer/devices/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    if (response) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("기기 상태 삭제 중 오류 발생:", error);
    errorCatch(error);
    return false;
  }
};
