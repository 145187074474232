import { useSelector } from "react-redux";
import DialogButton from "./Button";
import LoadingIndicator from "./LoadingIndicator";

export default function NoticeForm({
  icon,
  title,
  description,
  descriptionNext,
  isOneButton,
  cancelTitle,
  confirmTitle,
  handleConfirm,
  handleCancel,
  isWarnning,
}) {
  const isDialogLoading = useSelector((state) => state.common.isDialogLoading);
  return (
    <>
      <LoadingIndicator isLoading={isDialogLoading} />
      <div className="flex flex-col items-center justify-center bg-white h-full gap-[18px] px-[72px]">
        <div
          className={`${isWarnning ? "bg-negative-200" : "bg-primary-200"} w-[40px] h-[40px] p-[8px] rounded-[10px] shadow-custom`}
        >
          {icon}
        </div>
        <p className="text-[20px] font-semibold text-gray-500">{title}</p>
        {descriptionNext && (
          <p className="text-[18px] text-[#494a50] leading-[1.2] text-center">
            {description}
            <br />
            {descriptionNext}
          </p>
        )}
        {!descriptionNext && description && (
          <p className="text-[18px] text-[#494a50] leading-[1.2]">
            {description}
          </p>
        )}
        {isOneButton ? (
          <DialogButton onClick={handleConfirm}>{confirmTitle}</DialogButton>
        ) : (
          <div className="flex w-full gap-[12px]">
            <DialogButton isCancel={true} onClick={handleCancel}>
              {cancelTitle}
            </DialogButton>
            <DialogButton onClick={handleConfirm}>{confirmTitle}</DialogButton>
          </div>
        )}
      </div>
    </>
  );
}
