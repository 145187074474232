import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import Input from "../components/auth/Input";
import AuthFormat from "../components/auth/AuthFormat";

export default function AuthCodePage() {
  const { t } = useTranslation();
  const inputIsValid = useSelector(
    (state) => state.auth.codeState.inputIsValid
  );
  const isLoading = useSelector((state) => state.common.isLoading);
  return (
    <AuthFormat>
      <>
        <div className="text-gray30 font-medium font-poppins pt-[16px] pb-[37px]">
          <p className="text-gray30 font-medium font-poppins">
            {t("verify_code.header.description")}
            <br />
            {t("verify_code.header.description_next")}
          </p>
        </div>
        <div className="relative pt-[10px] mb-[50px]">
          <Input
            icon={
              <svg
                width="25"
                height="25"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.2 15C24.2 13.6764 23.1236 12.6 21.8 12.6H20.6V9C20.6 5.6916 17.9084 3 14.6 3C11.2916 3 8.6 5.6916 8.6 9V12.6H7.4C6.0764 12.6 5 13.6764 5 15V24.6C5 25.9236 6.0764 27 7.4 27H21.8C23.1236 27 24.2 25.9236 24.2 24.6V15ZM11 9C11 7.0152 12.6152 5.4 14.6 5.4C16.5848 5.4 18.2 7.0152 18.2 9V12.6H11V9Z"
                  fill={`${inputIsValid ? "#002A70" : "#868AA5"}`}
                />
              </svg>
            }
            placeholder={t("verify_code.code.placeholder")}
            authStep={"code"}
          >
            <div className="h-[82px]"></div>
          </Input>
          {inputIsValid && !isLoading && (
            <div className="absolute left-[20px] top-0 bg-white text-gray3c flex justify-center items-center w-[78px] h-[20px]">
              {t("verify_code.code.label")}
            </div>
          )}
        </div>
      </>
    </AuthFormat>
  );
}
