import axios from "axios";
import Config from "./Config";
import {
  getAccessToken,
  getEmail,
  getRefreshToken,
  removeTokens,
} from "../store/token";
import { refreshLogin } from "./auth";
import { useDispatch } from "react-redux";
import { authActions } from "../store/authSlice";
import i18n from "../i18n";
import { redirect, useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: Config.apiUrl,

  timeout: 1000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    "Accept-Language": i18n.language || "ko",
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken(); // 저장된 accessToken 가져오기
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // 액세스 토큰 설정
    }
    return config;
  },
  (error) => {
    // console.log("--------------request오류--------------");
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._retry // 여기서 _retry를 처음으로 추가해주는 부분
    ) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken(); // 리프레시 토큰 가져오기
      if (refreshToken) {
        const refreshSuccess = await refreshLogin({
          refreshToken: refreshToken,
          isTest: false,
        }); // 리프레시 API 호출
        if (refreshSuccess) {
          const newAccessToken = getAccessToken(); // 갱신된 액세스 토큰 가져오기
          //여기에 refresh api에서 받아온 access토큰을 쓴다.
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`; // 새로운 액세스 토큰 설정
          return api(originalRequest); // 실패한 요청 재시도
        } else {
          // console.log("--------------response오류--------------");
          removeTokens(); // 리프레시 토큰도 만료되었거나 오류가 발생한 경우 토큰 제거
          window.location.href = "/login"; // 로그인 페이지로 리다이렉트
        }
      }
    } else {
      // console.log("실패");
    }

    return Promise.reject(error); // 다른 에러는 그대로 반환
  }
);
