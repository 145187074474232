import axios from "axios";
import { api } from "./base";
import { errorCatch } from "./common";
export const inquiry = async ({ email, message, isTest }) => {
  try {
    const requestBody = {
      email,
      message,
    };
    const response = isTest
      ? await axios.post("/customer/help", requestBody)
      : await api.post("/customer/help", requestBody);
    return true;
  } catch (error) {
    errorCatch(error);
    return false;
  }
};
