import checkOnImg from "../../assets/icons/checkOn.png";
import checkOffImg from "../../assets/icons/checkOff.png";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/authSlice";
import { useTranslation } from "react-i18next";

export default function Terms() {
  const dispatch = useDispatch();
  const terms = useSelector((state) => state.auth.terms);
  const { t } = useTranslation();

  const handleToggle = () => {
    dispatch(authActions.setIsTermsChecked());
  };
  const handleOnClick = () => {
    dispatch(authActions.setTermsIsOpen(true));
  };
  return (
    <div className="h-[82px] flex justify-between">
      <span className="flex items-center">
        <div className="flex items-center">
          <img
            src={terms.isChecked ? checkOnImg : checkOffImg}
            alt="Checkbox"
            className="w-6 h-6 cursor-pointer"
            onClick={handleToggle}
          />
          <p className="mx-[12px] text-primary-600 font-semibold">
            {t("login.terms.agreement")}
          </p>
        </div>
      </span>
      <div className="flex items-center">
        <button
          className="text-[#393d6e] border-b-[1px] border-[#393d6e] leading-none"
          onClick={handleOnClick}
        >
          {t("login.terms.check")}
        </button>
      </div>
    </div>
  );
}
