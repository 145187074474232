import { useSelector } from "react-redux";

export default function LoadingIndicator({ isLoading }) {
  return (
    <div
      className={`${isLoading && `absolute z-10 left-0 right-0 bg-stone-600 w-full h-full bg-opacity-15`}`}
    >
      {isLoading && (
        <div className="spinner absolute z-10 left-0 right-0 top-[45%]"></div>
      )}
    </div>
  );
}
