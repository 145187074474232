import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import LoadingIndicator from "./components/dialogs/LoadingIndicator";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate
      loading={
        <div>
          <LoadingIndicator isLoading={true} />
        </div>
      }
      persistor={persistor}
    >
      {/*loading = {<div>Loading...</div>}  */}

      <ErrorBoundary>
        <Suspense
          fallback={
            <div>
              <LoadingIndicator isLoading={true} />
            </div>
          }
        >
          <App />
        </Suspense>
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);
reportWebVitals();
