import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    isLoading: false,
    isDialogLoading: false,
    isTableLoading: false,
  },
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsDialogLoading(state, action) {
      state.isDialogLoading = action.payload;
    },
    setIsTableLoading(state, action) {
      state.isTableLoading = action.payload;
    },
  },
});

export default commonSlice.reducer;
export const commonActions = commonSlice.actions;
