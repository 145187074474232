import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import selectSlice from "./selectSlice";
import storageSession from "redux-persist/lib/storage/session";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import tableSlice from "./tableSlice";
import deviceSlice from "./deviceSlice";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import commonSlice from "./commonSlice";
import inquirySlice from "./inquirySlice";
import dialogSlice from "./dialogSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  select: selectSlice,
  inquiry: inquirySlice,
  table: tableSlice,
  device: deviceSlice,
  common: commonSlice,
  dialog: dialogSlice,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["auth", "select", "dialog", "device", "table", "inquiry"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
