import { createSlice } from "@reduxjs/toolkit";

const tableSlice = createSlice({
  name: "table",
  initialState: {
    columnWidths: {
      col1: 133, //54
      col2: 291, //127
      col3: 267, //109
      col4: 189, //70
      col5: 213, //82
      col6: 95, //1 기존 -> 51
      col7: 237, //100
      col8: 135, //24
    },
    tableWidths: 0,
    tableHeights: 793,
  },
  reducers: {
    setColumnWidths(state, action) {
      state.columnWidths[action.payload.index] = action.payload.value;
    },
    setTableWidths(state, action) {
      state.tableWidths = action.payload;
    },
    setTableHeights(state, action) {
      state.tableHeights = action.payload;
    },
  },
});

export default tableSlice.reducer;
export const tableActions = tableSlice.actions;

// const [columnWidths, setColumnWidths] = useState({
//   col1: 133, //54
//   col2: 291, //127
//   col3: 267, //109
//   col4: 189, //70
//   col5: 213, //82
//   col6: 95, //1 기존 -> 51
//   col7: 237, //100
//   col8: 135, //24
// });
// const [tableWidths, setTableWidths] = useState();
// const [tableHeights, setTableHeights] = useState(793);
