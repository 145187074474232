import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/Modal";
import { useTranslation } from "react-i18next";
import { deviceActions } from "../../store/deviceSlice";
import registImg from "../../assets/icons/registDevice.png";
import SelectDevice from "../main/SelectDevice";
import "./styles.css";
import ButtonDeviceState from "./ButtonDeviceState";
import { useCallback, useEffect, useState } from "react";
import { registDevice } from "../../api/customerDevice";
import { dialogActions } from "../../store/dialogSlice";
import { commonActions } from "../../store/commonSlice";
import LoadingIndicator from "./LoadingIndicator";
import { getDevices } from "../../api/customerDevice";
import ErrorDescription from "../UI/ErrorDescription";

export default function DeviceRegistrationDialog() {
  const { t } = useTranslation();
  const location = "regist";
  const registrationDialog = useSelector(
    (state) => state.device.registrationDialog
  );
  const dispatch = useDispatch();
  const [onFocus, setOnFocus] = useState();
  const [isSubmet, setIsSubmit] = useState();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" || event.key === "Enter") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (registrationDialog.isOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [registrationDialog.isOpen]);
  const isDialogLoading = useSelector((state) => state.common.isDialogLoading);

  const selectedDialog = useSelector(
    (state) => state.device.registrationDialog
  );
  const classes = useCallback(
    ({ value }) => {
      console.log(`value = ${selectedDialog.serial.onFocus}`);
      return `${value !== "" ? "bg-white outline outline-2 outline-primary-400" : "bg-gray-100"} w-full h-[48px] px-[16px] rounded-[10px] focus:outline-primary-400`;
    },
    [selectedDialog.serial.onFocus]
  );
  function handleOnFocus(identifier) {
    setOnFocus(true);
    setIsSubmit(false);
  }
  function handleOnBlur(identifier) {
    setOnFocus(false);
  }
  function handleOnCancel() {
    dispatch(deviceActions.resetRegistration());
  }

  function handleOnChange(event) {
    dispatch(deviceActions.setSerial(event.target.value.trim()));
  }
  async function handleOnConfirm() {
    dispatch(deviceActions.setIsVerified(true));
    setIsSubmit(true);
    const selectedState = checkDeviceState();
    dispatch(commonActions.setIsDialogLoading(true));
    const response = await registDevice({
      prodName: selectedDialog.selectedDeviceModel.prodName,
      regCode: selectedDialog.selectedDeviceModel.regCode,
      serial: selectedDialog.serial.value,
      deviceState: selectedState,
    });
    dispatch(commonActions.setIsDialogLoading(false));
    if (response.isPassed) {
      setIsSubmit(false);
      dispatch(deviceActions.setIsOpen({ location, isOpen: false }));
      const fetchDevices = async () => {
        dispatch(commonActions.setIsTableLoading(true));
        const response = await getDevices({ isTest: false });
        dispatch(commonActions.setIsTableLoading(false));
        if (response) {
          const data = response.content;
          if (data) {
            dispatch(deviceActions.setDevices(data));
            dispatch(dialogActions.setRegistCompleteIsOpen(true));
            // console.log("저장된 기기들", data);
            dispatch(deviceActions.resetRegistration());
          } else {
            console.log("기기 없음");
          }
        } else {
          console.log("Devices 호출 실패", response);
        }
      };
      fetchDevices();
    } else {
      console.log("코드", response);
      if (response.errorCode === 400) {
        dispatch(deviceActions.setIsVerified(false));
      } else if (response.errorCode === 409) {
        dispatch(deviceActions.setIsVerified(true));
      }
    }
  }
  function checkDeviceState() {
    const deviceState = {
      ok: "ok",
      failure: "failure",
      dispose: "dispose",
    };
    return deviceState[
      Object.keys(selectedDialog.deviceState).find(
        (key) => selectedDialog.deviceState[key] === true
      )
    ];
  }

  return (
    <Modal
      open={registrationDialog.isOpen}
      className={`modal modal_input w-[610px] `}
    >
      <>
        <LoadingIndicator isLoading={isDialogLoading} />
        <div className="flex flex-col h-full ">
          <header className="px-[37px] pt-[28px] border-b-[0.5px] border-gray-400 border-opacity-50 pb-[15.8px]">
            <div className="flex items-center">
              <div className="w-[40px] h-[40px] flex items-center justify-center bg-primary-200 shadow-custom">
                <img
                  src={registImg}
                  className="h-[30px] w-[30px]"
                  alt="regist"
                />
              </div>
              <h2 className="pl-[12px] text-[24px] font-bold">
                {t("regist_device_dialog.title")}
              </h2>
            </div>
          </header>

          <main className="flex-grow h-full flex flex-col px-[53px]">
            <div className="flex justify-center pt-[39.8px] pb-[40px]">
              <div className="relative w-full flex flex-col gap-[8px]">
                <p className="text-primary-700 leading-none">
                  {t("regist_device_dialog.model.label")}
                </p>

                <SelectDevice />
              </div>
            </div>
            <div className="flex gap-[54px]">
              <div className="w-full flex flex-col gap-[8px]">
                <p className="text-primary-700 leading-none">
                  {t("regist_device_dialog.reg_number.label")}
                </p>
                <input
                  readOnly
                  value={selectedDialog.selectedDeviceModel.regCode}
                  className={classes({
                    value: selectedDialog.selectedDeviceModel.regCode,
                  })}
                  type="text"
                  placeholder={t("regist_device_dialog.reg_number.placeholder")}
                />
              </div>
              <div className="relative w-full flex flex-col gap-[8px]">
                <p className="text-primary-700 leading-none">
                  {t("regist_device_dialog.device_serial.label")}
                </p>

                <input
                  onFocus={() =>
                    handleOnFocus({
                      identifier: "serial",
                    })
                  }
                  onBlur={() => handleOnBlur({ identifier: location })}
                  value={selectedDialog.serial.value}
                  onChange={handleOnChange}
                  className={`${selectedDialog.serial.value !== "" || onFocus ? "bg-white outline outline-2 outline-primary-400" : "bg-gray-100"} w-full h-[48px] px-[16px] rounded-[10px] focus:outline-primary-400`}
                  type="text"
                  placeholder={t(
                    "regist_device_dialog.device_serial.placeholder"
                  )}
                />
                {!onFocus &&
                  isSubmet &&
                  !registrationDialog.serial.isVerified && (
                    <ErrorDescription
                      description={t(
                        "regist_device_dialog.device_serial.error_description"
                      )}
                      isTopFull={true}
                    />
                  )}
              </div>
            </div>
            <div className="h-[40px]"></div>
            <div className="w-full flex flex-col gap-[8px]">
              <p className="text-primary-700 leading-none">
                {t("regist_device_dialog.device_state.label")}
              </p>
              <div className="flex gap-[12px] h-[48px]">
                <ButtonDeviceState
                  label={t("regist_device_dialog.device_state.ok")}
                  isSelected={selectedDialog.deviceState.ok}
                  onClick={() => {
                    dispatch(
                      deviceActions.setDeviceState({ identifier: "ok" })
                    );
                  }}
                />
                <ButtonDeviceState
                  label={t("regist_device_dialog.device_state.failure")}
                  isSelected={selectedDialog.deviceState.failure}
                  onClick={() => {
                    dispatch(
                      deviceActions.setDeviceState({ identifier: "failure" })
                    );
                  }}
                />
                <ButtonDeviceState
                  label={t("regist_device_dialog.device_state.dispose")}
                  isSelected={selectedDialog.deviceState.dispose}
                  onClick={() => {
                    dispatch(
                      deviceActions.setDeviceState({ identifier: "dispose" })
                    );
                  }}
                />
              </div>
            </div>
            <div className="h-[22px]"></div>
            <div className="button-container">
              <button
                onClick={handleOnCancel}
                className="common-button cancel-button"
              >
                {t("button.cancel")}
              </button>
              <button
                onClick={handleOnConfirm}
                disabled={!selectedDialog.inputState}
                className={`common-button confirm-button ${!selectedDialog.inputState ? "confirm-button-disabled" : "confirm-button-enabled"}`}
              >
                {t("button.create")}
              </button>
            </div>
          </main>
        </div>
      </>
    </Modal>
  );
}
