const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h1 style={{ fontSize: "3rem", margin: "0" }}>404</h1>
      <p style={{ fontSize: "1.5rem" }}>페이지를 찾을 수 없습니다.</p>
      <a
        href="/"
        style={{ fontSize: "1rem", color: "blue", textDecoration: "underline" }}
      >
        홈으로 돌아가기
      </a>
    </div>
  );
};

export default NotFoundPage;
