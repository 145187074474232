export default function ButtonSide({ iconSrc, children, ...props }) {
  return (
    <button
      className="flex flex-col items-center justify-between h-[120px] shadow-custom rounded-[12px] bg-white text-primary-500 py-[27px]"
      {...props}
    >
      <img src={iconSrc} className="w-[30px] h-[30px]" alt={children}></img>
      <p>{children}</p>
    </button>
  );
}
