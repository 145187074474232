import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/Modal";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { useCallback, useEffect, useState } from "react";
import emailImg from "../../assets/icons/mailPng.png";
import { commonActions } from "../../store/commonSlice";
import LoadingIndicator from "./LoadingIndicator";
import { getDevices } from "../../api/customerDevice";
import { changeEmail, verifyEmail } from "../../api/customer";
import { authActions } from "../../store/authSlice";
import { dialogActions } from "../../store/dialogSlice";
import ErrorDescription from "../UI/ErrorDescription";

export default function ChangeEmailVerifyDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [onFocus, setOnFocus] = useState(false);
  const [input, setInput] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const isOpen = useSelector(
    (state) => state.auth.newEmailState.changeEmailVerifyDialog.isOpen
  );
  const newEmailState = useSelector((state) => state.auth.newEmailState);
  const newCodeState = useSelector((state) => state.auth.newCodeState);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" || event.key === "Enter") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen]);
  const isDialogLoading = useSelector((state) => state.common.isDialogLoading);

  function handleOnFocus() {
    setOnFocus(true);
  }
  function handleOnBlur() {
    setOnFocus(false);
  }
  function handleOnCancel() {
    setInput("");
    dispatch(authActions.setNewEmail(""));
    dispatch(authActions.resetIsVerified("newEmail"));
    dispatch(authActions.setChangeEmailVerifyIsOpen(false));
  }
  function handleOnChange(event) {
    setInput(event.target.value.trim());
    setIsSubmit(false);
  }
  async function handleOnConfirm() {
    dispatch(commonActions.setIsDialogLoading(true));
    const response = await changeEmail({
      email: newEmailState.newEmail,
      verifyCode: input,
    });
    dispatch(commonActions.setIsDialogLoading(false));
    if (response) {
      dispatch(authActions.setEmail(response.email));
      dispatch(authActions.setChangeEmailVerifyIsOpen(false));
      dispatch(dialogActions.setChangeCompleteIsOpen(true));
      setIsSubmit(false);
      setInput("");
    } else {
      setIsSubmit(true);
      dispatch(
        authActions.setIsVerified({ value: false, authStep: "newCode" })
      );
    }
  }

  return (
    <Modal open={isOpen} className={`modal modal_input w-[610px] `}>
      <>
        <LoadingIndicator isLoading={isDialogLoading} />
        <div className="flex flex-col h-full">
          <header className="px-[37px] pt-[28px] border-b-[0.5px] border-gray-400 border-opacity-50 pb-[20.8px]">
            <div className="flex items-center pb-[16px]">
              <div className="w-[40px] h-[40px] flex items-center justify-center bg-primary-200 shadow-custom">
                <img
                  src={emailImg}
                  className="h-[30px] w-[30px]"
                  alt="regist"
                />
              </div>
              <h2 className="pl-[12px] text-[24px] font-bold">
                {t("change_email_dialog.header.title")}
              </h2>
            </div>
            <p className="pl-[9.5px] font-[500] text-gray-400">
              {t("change_email_dialog.verify_code.description")}
            </p>
          </header>

          <main className="flex-grow h-full flex flex-col px-[53px] pt-[39.8px]">
            <div className="relative w-full flex flex-col gap-[8px]">
              <p className="text-primary-700 leading-none">
                {t("change_email_dialog.verify_code.label")}
              </p>
              <input
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                value={input}
                onChange={handleOnChange}
                className={`${input !== "" || onFocus ? "bg-white outline outline-2 outline-primary-400" : "bg-gray-100"} w-full h-[48px] px-[16px] rounded-[10px] focus:outline-primary-400`}
                type="text"
                placeholder={t("change_email_dialog.verify_code.placeholder")}
              />
              {!onFocus &&
                input !== "" &&
                isSubmit &&
                !newCodeState.isVerified && (
                  <ErrorDescription
                    description={t("verify_code.code.error_description")}
                    isTopFull={true}
                  />
                )}
            </div>

            <div className="button-container mb-[2px]">
              <button
                onClick={handleOnCancel}
                className="common-button cancel-button-email"
              >
                {t("button.cancel")}
              </button>
              <button
                onClick={handleOnConfirm}
                disabled={!input}
                className={`common-button confirm-button-email ${!input ? "confirm-button-disabled" : "confirm-button-enabled"}`}
              >
                {t("button.create")}
              </button>
            </div>
          </main>
        </div>
      </>
    </Modal>
  );
}
