export default function Button({ children, isEntered, ...props }) {
  let classes =
    "text-gray-300 py-[20px] text-[22px] px-[14px] rounded-[10px] w-full";
  if (isEntered) {
    classes += " text-white bg-primary-500";
  } else {
    classes += " text-gray-300 bg-gray-100";
  }

  return (
    <button className={classes} {...props} disabled={!isEntered}>
      {children}
    </button>
  );
}
