import { createSlice } from "@reduxjs/toolkit";

const registrationDialogInit = {
  selectedDeviceModel: { prodName: "", regCode: "" },
  onFocus: false,
  isOpen: false,
  prodName: { isOpen: false, value: "", onFocus: false },
  serial: { value: "", onFocus: false, isVerified: false },
  connectionState: "",
  deviceState: {
    ok: false,
    failure: false,
    dispose: false,
  },
  inputState: false,
};
const modificationDialogInit = {
  id: "",
  prodName: "",
  regCode: "",
  isOpen: false,
  serial: "",
  registeredAt: "",
  deviceState: {
    ok: false,
    failure: false,
    dispose: false,
  },
};

const initialState = {
  devices: [],
  devicesModel: [],
  registrationDialog: registrationDialogInit,
  modificationDialog: modificationDialogInit,
  selectedDeviceId: "",
};

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    resetAll(state) {
      return initialState;
    },
    resetRegistration(state) {
      state.registrationDialog = { ...registrationDialogInit };
    },
    resetModification(state) {
      state.modificationDialog = { ...modificationDialogInit };
    },
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload;
    },
    setDevices(state, action) {
      state.devices = [...action.payload];
    },
    setDeviceModel(state, action) {
      state.devicesModel = [...action.payload];
    },
    setIsOpen(state, action) {
      if (action.payload.location === "regist") {
        state.registrationDialog.isOpen = action.payload.isOpen;
      } else if (action.payload.location === "modify") {
        state.modificationDialog.isOpen = action.payload.isOpen;
      }
    },
    setSelectIsOpen(state) {
      state.registrationDialog.prodName.isOpen =
        !state.registrationDialog.prodName.isOpen;
    },
    setSelectDeviceModel(state, action) {
      state.registrationDialog.selectedDeviceModel = action.payload;
      if (
        state.registrationDialog.selectedDeviceModel.prodName !== "" &&
        state.registrationDialog.serial.value !== "" &&
        (state.registrationDialog.deviceState.ok ||
          state.registrationDialog.deviceState.failure ||
          state.registrationDialog.deviceState.dispose)
      ) {
        state.registrationDialog.inputState = true;
      } else {
        state.registrationDialog.inputState = false;
      }
    },
    setOnFocus(state, action) {
      if (action.payload.location === "regist") {
        if (action.payload.identifier === "prodName") {
          state.registrationDialog.prodName.onFocus = action.payload.onFocus;
        } else if (action.payload.identifier === "serial") {
          state.registrationDialog.serial.onFocus = action.payload.onFocus;
        }
      }
      //    else if (action.payload.location === "modify") {
      //   }
    },
    setSerial(state, action) {
      state.registrationDialog.serial.value = action.payload;
      if (
        state.registrationDialog.selectedDeviceModel.prodName !== "" &&
        state.registrationDialog.serial.value !== "" &&
        (state.registrationDialog.deviceState.ok ||
          state.registrationDialog.deviceState.failure ||
          state.registrationDialog.deviceState.dispose)
      ) {
        state.registrationDialog.inputState = true;
      } else {
        state.registrationDialog.inputState = false;
      }
    },
    setDeviceState(state, action) {
      if (action.payload.identifier === "ok") {
        state.registrationDialog.deviceState.ok =
          !state.registrationDialog.deviceState.ok;
        if (state.registrationDialog.deviceState.ok) {
          state.registrationDialog.deviceState.dispose = false;
          state.registrationDialog.deviceState.failure = false;
        }
      } else if (action.payload.identifier === "failure") {
        state.registrationDialog.deviceState.failure =
          !state.registrationDialog.deviceState.failure;
        if (state.registrationDialog.deviceState.failure) {
          state.registrationDialog.deviceState.dispose = false;
          state.registrationDialog.deviceState.ok = false;
        }
      } else if (action.payload.identifier === "dispose") {
        state.registrationDialog.deviceState.dispose =
          !state.registrationDialog.deviceState.dispose;
        if (state.registrationDialog.deviceState.dispose) {
          state.registrationDialog.deviceState.ok = false;
          state.registrationDialog.deviceState.failure = false;
        }
      }
      if (
        state.registrationDialog.selectedDeviceModel.prodName !== "" &&
        state.registrationDialog.serial.value !== "" &&
        (state.registrationDialog.deviceState.ok ||
          state.registrationDialog.deviceState.failure ||
          state.registrationDialog.deviceState.dispose)
      ) {
        state.registrationDialog.inputState = true;
      } else {
        state.registrationDialog.inputState = false;
      }
    },
    getModifyDialogData(state, action) {
      state.modificationDialog.id = action.payload.id;
      state.modificationDialog.prodName = action.payload.prodName;
      state.modificationDialog.regCode = action.payload.regCode;
      state.modificationDialog.serial = action.payload.serial;
      state.modificationDialog.deviceState.ok = false;
      state.modificationDialog.deviceState.failure = false;
      state.modificationDialog.deviceState.dispose = false;
      state.modificationDialog.deviceState[action.payload.deviceState] = true;
    },
    setModifyDialogData(state, action) {
      if (action.payload.identifier === "ok") {
        state.modificationDialog.deviceState.ok = true;

        state.modificationDialog.deviceState.dispose = false;
        state.modificationDialog.deviceState.failure = false;
      } else if (action.payload.identifier === "failure") {
        state.modificationDialog.deviceState.failure = true;

        state.modificationDialog.deviceState.dispose = false;
        state.modificationDialog.deviceState.ok = false;
      } else if (action.payload.identifier === "dispose") {
        state.modificationDialog.deviceState.dispose = true;
        state.modificationDialog.deviceState.ok = false;
        state.modificationDialog.deviceState.failure = false;
      }
    },
    setIsVerified(state, action) {
      state.registrationDialog.serial.isVerified = action.payload;
    },
  },
});

export default deviceSlice.reducer;

export const deviceActions = deviceSlice.actions;
