import { useTranslation } from "react-i18next";
import logoImg from "../assets/images/logo.png";

import { LANGUAGE } from "../store/selectSlice";
import languageImg from "../assets/icons/language.png";
import profileImg from "../assets/icons/profile.png";
import { useDispatch, useSelector } from "react-redux";
import TableView from "../components/main/TableView";
import SelectLanguage from "../components/UI/SelectLanguage";
import Side from "../components/main/SideView";
import { removeTokens } from "../store/token";
// import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import {
  Link,
  useBlocker as useBlockerCore,
  BlockerFunction,
  useLocation,
  Location,
  useNavigate,
  useBlocker,
  redirect,
  replace,
} from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";

import { deviceActions } from "../store/deviceSlice";
import {
  getDevices,
  getDeviceStates,
  getProducts,
} from "../api/customerDevice";
import { commonActions } from "../store/commonSlice";
import LoadingIndicator from "../components/dialogs/LoadingIndicator";
import { dialogActions } from "../store/dialogSlice";
import LogoutCheckDialog from "../components/dialogs/LogoutCheck";
import { authActions } from "../store/authSlice";
import { useHistory } from "react-router-dom";
export default function HomePage() {
  const { t } = useTranslation();
  const emailState = useSelector((state) => state.auth.emailState);
  const navigate = useNavigate();
  const location = useLocation();
  const devices = useSelector((state) => state.device.devices);
  const dispatch = useDispatch();

  const prevLocation = useRef(location);
  const [isDialogOpen, setDialogOpen] = useState(false);
  // const blocker = useBlocker((tx) => {
  //   console.log("tx", tx);
  //   console.log("tx.currentLocation", tx.currentLocation.pathname);
  //   console.log("tx.nextLocation", tx.nextLocation.pathname);
  //   if (
  //     tx.currentLocation.pathname === "/home" &&
  //     tx.nextLocation.pathname === "/code"
  //   ) {
  //     console.log("뒤로가기");
  //     const confirmLeave = window.confirm("로그아웃하시겠습니까?");
  //     if (confirmLeave) {
  //       console.log("확인버튼");
  //       //window.location.href = "/";
  //       navigate("/");
  //     } else {
  //       console.log("취소버튼");

  //       window.history.pushState(null, null, tx.currentLocation.pathname);
  //       //window.location.href = "/home";
  //       //navigate("/home");
  //       window.location.reload();

  //       console.log("tx", tx);
  //     }
  //   } else if (
  //     tx.currentLocation.pathname === "/code" &&
  //     tx.nextLocation.pathname === "/home"
  //   ) {
  //     console.log("앞으로가기");
  //   }
  // });
  //console.log("blocker", blocker);

  // useEffect(() => {
  //   console.log("이펙트 실행1");
  //   const handlePopState = (event) => {
  //     // 취소 버튼을 누르면 브라우저가 뒤로 가는 것을 방지
  //     console.log("이펙트 실행2", event);
  //     window.history.pushState(null, null, location.pathname);
  //   };

  //   // 브라우저의 popstate 이벤트를 감지하여 뒤로가기를 방지
  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     // 컴포넌트가 언마운트되면 이벤트 리스너 제거
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [location.pathname]);

  useEffect(() => {
    const fetchDevices = async () => {
      dispatch(commonActions.setIsTableLoading(true));
      const response = await getDevices({ isTest: false });
      dispatch(commonActions.setIsTableLoading(false));
      if (response) {
      } else {
        console.log("Devices 호출 실패", response);
      }
      const data = response.content;
      if (data) {
        dispatch(deviceActions.setDevices(data));
      } else {
        console.log("기기 없음");
      }
    };
    fetchDevices();
    const fetchModels = async () => {
      const response = await getProducts({ isTest: false });

      if (response) {
        if (response.content.length > 0) {
          dispatch(deviceActions.setDeviceModel(response.content));
        }
      } else {
        //Todo 예외처리
        // console.error("Models 호출 실패");
      }
    };
    // const fetchDeviceStates = async () => {
    //   const response = await getDeviceStates();
    //   // if (response) {
    //   //   console.log("Devices States 호출 성공");
    //   // } else {
    //   //   console.error("Devices States 호출 실패");
    //   // }
    // };
    fetchModels();
    // fetchDeviceStates();
  }, []);

  function handleOnLogout() {
    // removeTokens();
    // navigate("/");
    dispatch(dialogActions.setLogoutCheckIsOpen(true));
  }
  const isTableLoading = useSelector((state) => state.common.isTableLoading);
  const handleOnConfirm = () => {
    dispatch(commonActions.setIsDialogLoading(true));
    dispatch(authActions.resetAll());
    dispatch(deviceActions.resetAll());
    removeTokens();
    navigate("/login", { replace: true });
    dispatch(commonActions.setIsDialogLoading(false));
    dispatch(dialogActions.setLogoutCheckIsOpen(false));
    dispatch(dialogActions.setLogoutCompleteIsOpen(true));
  };
  return (
    <>
      <LogoutCheckDialog onConfirm={handleOnConfirm} />
      <div className="flex h-screen">
        <aside className="w-[258px] flex flex-col px-[23px] shadow-custom z-30 bg-white">
          <div className="w-full pt-[59px] pb-[47.5px] h-[158.5px] flex justify-center">
            <img src={logoImg} className="w-[146px]"></img>
          </div>
          <div className="w-full h-[1px]"></div>
          <Side />
          <div className="h-[136px] w-full flex items-center justify-center">
            <button
              className="h-[36px] w-[120px] flex rounded-[12px] bg-gray-200 px-[14px] justify-between items-center"
              onClick={handleOnLogout}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.185 2.01251C20.1642 2.01083 20.1467 2 20.125 2H11.1667C9.78839 2 8.66672 3.12167 8.66672 4.5V5.33328C8.66672 5.79333 9.03995 6.16672 9.5 6.16672C9.96005 6.16672 10.3333 5.79333 10.3333 5.33328V4.5C10.3333 4.04086 10.7074 3.66672 11.1667 3.66672H15.0492L14.795 3.75171C14.1201 3.98502 13.6667 4.62085 13.6667 5.33328V17.8333H11.1667C10.7074 17.8333 10.3333 17.4591 10.3333 17V15.3333C10.3333 14.8734 9.96005 14.5 9.5 14.5C9.03995 14.5 8.66672 14.8734 8.66672 15.3333V17C8.66672 18.3783 9.78839 19.5 11.1667 19.5H13.6667V20.3333C13.6667 21.2525 14.4141 22 15.3333 22C15.5117 22 15.6809 21.9742 15.8641 21.9174L20.8708 20.2483C21.5467 20.015 22 19.3792 22 18.6667V3.66672C22 2.69504 21.1624 1.93332 20.185 2.01251Z"
                  fill="#7D7D7D"
                />
                <path
                  d="M10.9224 9.74413L7.58914 6.41085C7.3508 6.17251 6.99252 6.10079 6.68079 6.23003C6.36996 6.35912 6.16672 6.66338 6.16672 6.99999V9.49999H2.83328C2.37338 9.49999 2 9.87338 2 10.3333C2 10.7933 2.37338 11.1667 2.83328 11.1667H6.16672V13.6667C6.16672 14.0033 6.36996 14.3074 6.68079 14.4367C6.99252 14.5658 7.3508 14.4942 7.58914 14.2559L10.9224 10.9224C11.2484 10.5966 11.2484 10.0701 10.9224 9.74413Z"
                  fill="#7D7D7D"
                />
              </svg>

              <p className="text-gray-400 font-semibold">
                {t("main.sidebar.logout")}
              </p>
            </button>
          </div>
        </aside>

        <div className="flex flex-col w-full overflow-auto">
          <header className="bg-white shadow-custom w-full h-[113px] pb-[21px] z-5 flex pl-[67px] pr-[64px] items-end justify-between z-20">
            <h2 className="text-[26px] font-semibold text-gray-300 pb-[14px] leading-none">
              {t("main.header.title")}
            </h2>
            <div className="flex items-center h-[36px]">
              <img
                src={profileImg}
                className="w-[30px] h-[30px]"
                alt="profile"
              />
              <div className="w-[12px]"></div>
              <p>{emailState.email}</p>
              <div className="w-[32px]"></div>
              <div className="w-[1px] bg-gray-300 h-full "></div>
              <div className="w-[32px]"></div>
              <div className="relative w-[296px] h-full flex items-center">
                <img
                  src={languageImg}
                  alt="language icon"
                  className="w-[24px] h-[24px]"
                />
                <SelectLanguage options={LANGUAGE} />
              </div>
            </div>
          </header>
          {isTableLoading ? (
            <LoadingIndicator isLoading={true} />
          ) : (
            <TableView />
          )}
        </div>
      </div>
    </>
  );
}
