export default function ButtonDeviceState({ label, isSelected, ...props }) {
  return (
    <button
      className={`${isSelected ? "bg-primary-500 text-white" : "bg-white text-gray8b"} w-full h-full rounded-[10px]`}
      style={{ border: isSelected ? "1px solid #007dfa" : "1px solid #dcdfe3" }}
      {...props}
    >
      {label}
    </button>
  );
}
