import loginImg from "../../assets/images/login.png";
import logoImg from "../../assets/images/logo.png";

import inquiryImg from "../../assets/icons/inquiry.png";
import languageImg from "../../assets/icons/language.png";
import { LANGUAGE } from "../../store/selectSlice";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import SelectLanguage from "../UI/SelectLanguage";
import { inquiryActions } from "../../store/inquirySlice";

export default function AuthFormat({ children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  function handleQuestion() {
    dispatch(inquiryActions.setEmailQuestionIsOpen(true));
  }
  return (
    <div className="h-screen flex" data-testid="verify-code-header">
      <aside className="w-1/2 flex-grow">
        <img
          className="object-cover w-full min-h-screen h-full"
          src={loginImg}
          alt="Login Side Image"
        />
      </aside>
      <main className="w-1/2 flex items-center justify-center ">
        <div className="w-full h-full pb-[90px] md:w-[480px]">
          <div className="h-[31%] flex items-center">
            <img src={logoImg} alt="Logo Image" className="w-[52%]" />
          </div>
          <h2 className="text-[28px] text-[#2f3367] font-bold">
            {t("login.header.title")}
          </h2>
          {children}
          <div className="my-[90px] flex h-[36px] items-center justify-between">
            <button onClick={handleQuestion} className="py-1">
              <div className="flex w-[95px]">
                <img src={inquiryImg} className="w-[24px] h-[24px] mr-[12px]" />
                <p className="text-primary-500">{t("login.inquiry")}</p>
              </div>
            </button>
            <div className="border-r-[1px] border-[#ddd] h-full"></div>
            <div className="relative w-[296px] h-full flex items-center">
              <img
                src={languageImg}
                alt="language icon"
                className="w-[24px] h-[24px]"
              />
              <SelectLanguage options={LANGUAGE} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
