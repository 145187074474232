import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/Modal";

import checkCircleImg from "../../assets/icons/check-circle.png";

import DialogButton from "./Button";
import { useTranslation } from "react-i18next";
import "./styles.css";
import NoticeForm from "./NoticeForm";
import { dialogActions } from "../../store/dialogSlice";
import { useEffect } from "react";

export default function CompleteDialog() {
  const completeIsOpen = useSelector(
    (state) => state.dialog.sendComplete.isOpen
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleConfirm() {
    dispatch(dialogActions.setSendCompleteIsOpen(false));
  }
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (completeIsOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [completeIsOpen]);

  return (
    <Modal
      open={completeIsOpen}
      className={"modal w-[476px] h-[266px] modal_notice"}
    >
      <NoticeForm
        icon={<img src={checkCircleImg} alt={"complete"} />}
        title={t("login.inquiry_dialog.sent_title")}
        description={t("login.inquiry_dialog.sent_description")}
        isOneButton={true}
        confirmTitle={t("button.confirm")}
        handleConfirm={handleConfirm}
      />
    </Modal>
  );
}
