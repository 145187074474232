import { useTranslation } from "react-i18next";
import ButtonSide from "./ButtonSide";
import registDevice from "../../assets/icons/registDevice.png";
import changeEmail from "../../assets/icons/changeEmail.png";
import inquiry from "../../assets/icons/inquiry.png";
import { useDispatch } from "react-redux";
import { deviceActions } from "../../store/deviceSlice";
import { inquiryActions } from "../../store/inquirySlice";
import { authActions, s } from "../../store/authSlice";
export default function Side() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  function handleOnQuestion() {
    dispatch(inquiryActions.setEmailQuestionIsOpen(true));
  }
  function handleOnChangeEmail() {
    dispatch(authActions.setChangeEmailIsOpen(true));
  }
  return (
    <div className="h-full items-center px-[26px] py-[22.5px] border-t-[0.5px] border-b-[0.5px] border-opacity-50 border-gray-400">
      <div className="flex flex-col h-full w-full justify-center">
        <ButtonSide
          iconSrc={registDevice}
          onClick={() =>
            dispatch(
              deviceActions.setIsOpen({ location: "regist", isOpen: true })
            )
          }
        >
          {t("main.sidebar.registration")}
        </ButtonSide>
        <div className="h-[72px]"></div>
        <ButtonSide onClick={handleOnChangeEmail} iconSrc={changeEmail}>
          {t("main.sidebar.change_email")}
        </ButtonSide>
        <div className="h-[62px]"></div>
        <ButtonSide onClick={handleOnQuestion} iconSrc={inquiry}>
          {t("main.sidebar.inquiry")}
        </ButtonSide>
      </div>
    </div>
  );
}
