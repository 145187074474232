import React, { useRef, useEffect } from "react";
import downImg from "../../assets/icons/down.png";
import upImg from "../../assets/icons/up.png";
import checkImg from "../../assets/icons/check.png";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { deviceActions } from "../../store/deviceSlice";

export default function SelectDevice() {
  const isOpen = useSelector(
    (state) => state.device.registrationDialog.prodName.isOpen
  );
  const selectedDeviceModel = useSelector(
    (state) => state.device.registrationDialog.selectedDeviceModel
  );
  const { t } = useTranslation();
  const selectClasses = `${selectedDeviceModel.prodName ? "border-2 border-primary-400 bg-white" : "bg-gray-100"} h-[48px] appearance-none rounded-[10px] items-center w-full px-[18px] flex justify-between`;
  const devicesModel = useSelector((state) => state.device.devicesModel);
  const dispatch = useDispatch();
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    dispatch(deviceActions.setSelectIsOpen());
  };
  const handleSelect = (value) => {
    dispatch(deviceActions.setSelectDeviceModel(value));
    dispatch(deviceActions.setSelectIsOpen());
  };

  const handleClickOutside = (event) => {
    if (
      selectRef.current &&
      dropdownRef.current &&
      !selectRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      dispatch(deviceActions.setSelectIsOpen());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <button className={selectClasses} onClick={handleToggle} ref={selectRef}>
        {selectedDeviceModel.prodName || (
          <p className="text-gray8b">
            {t("regist_device_dialog.model.placeholder")}
          </p>
        )}

        <div className="h-full flex items-center">
          <img
            src={isOpen ? upImg : downImg}
            alt="Dropdown Arrow"
            className="w-[30px] h-[30px]"
          />
        </div>
      </button>
      {isOpen && (
        <div
          className="absolute top-[calc(100%+8px)] w-[calc(100%)] bg-white border border-gray-300 rounded-lg shadow-lg z-10"
          ref={dropdownRef}
        >
          <ul className="list-none m-0 p-0">
            {devicesModel.map((deviceModel) => {
              return (
                <li
                  key={deviceModel.prodName}
                  className={`px-[12px] hover:bg-primary-200 cursor-pointer my-[8px] hover:outline outline-2 outline-primary-400 mx-[4px] rounded-[10px] ${selectedDeviceModel.prodName === deviceModel.prodName && "bg-primary-300"}`} //
                  onClick={() => {
                    handleSelect(deviceModel);
                  }}
                >
                  <div className="flex items-center h-[38px] justify-between">
                    {deviceModel.prodName}
                    {selectedDeviceModel.prodName === deviceModel.prodName && (
                      <img src={checkImg} className="w-[30px] h-[30px]" />
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
