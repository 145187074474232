import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dialogActions } from "../../store/dialogSlice";
import Modal from "../UI/Modal";
import NoticeForm from "./NoticeForm";
import checkCircleImg from "../../assets/icons/check-circle.png";
import LoadingIndicator from "./LoadingIndicator";
import { useEffect } from "react";

export default function LogoutCompleteDialog() {
  const isOpen = useSelector((state) => state.dialog.logoutComplete.isOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleConfirm() {
    dispatch(dialogActions.setLogoutCompleteIsOpen(false));
  }
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen]);
  return (
    <Modal open={isOpen} className={"modal w-[476px] h-[210px] modal_notice"}>
      <NoticeForm
        icon={<img src={checkCircleImg} alt={"complete"} />}
        title={t("logout_dialog.conf_noti")}
        isOneButton={true}
        confirmTitle={t("button.confirm")}
        handleConfirm={handleConfirm}
      />
    </Modal>
  );
}
