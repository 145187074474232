import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Resizable } from "react-resizable";
import { usePagination, useSortBy, useTable } from "react-table";
import "react-resizable/css/styles.css";
import tableLeft from "../../assets/icons/tableLeft.png";
import tableRight from "../../assets/icons/tableRight.png";
import ButtonTablePage from "./ButtonTablePage";
import downImg from "../../assets/icons/down.png";
import upImg from "../../assets/icons/up.png";
import emptyTableImg from "../../assets/images/emptyTable.png";
import showingDownImg from "../../assets/icons/showingDown.png";
import onlineImg from "../../assets/icons/online.png";
import offlineImg from "../../assets/icons/offline.png";
import deleteImg from "../../assets/icons/stateDelete.png";
import editImg from "../../assets/icons/stateEdit.png";

import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { deviceActions } from "../../store/deviceSlice";
import LoadingIndicator from "../dialogs/LoadingIndicator";
import { commonActions } from "../../store/commonSlice";
import { deleteDevice, getDevices } from "../../api/customerDevice";
import { dialogActions } from "../../store/dialogSlice";

export default function TableView() {
  const data = useSelector((state) => state.device.devices);

  const mainRef = useRef(null);
  const menuRef = useRef(null);
  let menuHeight = 80; // 예: 메뉴 높이
  const [menuPlacement, setMenuPlacement] = useState("bottom");
  const [showingList, setShowingList] = useState([]);
  useEffect(() => {
    if (data.length < 20) {
      menuHeight = 80;
      setMenuPlacement("bottom");
    } else {
      menuHeight = 150;
      setMenuPlacement("top");
    }
    const totalData = data.length;

    const quot = Math.floor(totalData / 10);

    if (quot > 0) {
      let sequence = [10]; // 초기값 10 설정

      for (let i = 1; i < Math.min(quot, 2); i++) {
        let prevSum = sequence.reduce((acc, num) => acc + num, 0); // 이전 값들의 합
        let nextValue = sequence[i - 1] + prevSum; // 새로운 값 계산
        sequence.push(nextValue);
      }
      if (quot > 4) {
        sequence.push(50);
      }
      setShowingList(sequence);
    }
  }, [data]);

  const handleSelectFocus = () => {
    if (mainRef.current) {
      if (data.length < 20) {
        mainRef.current.scrollTo({
          top: mainRef.current.scrollHeight, // 전체 스크롤 높이로 이동
        });
      }
    }

    if (menuRef.current) {
      const menuHeight = menuRef.current.getBoundingClientRect().height;
    }
  };
  useEffect(() => {
    const checkOverflow = () => {
      if (mainRef.current) {
        const { scrollHeight, clientHeight } = mainRef.current;

        if (scrollHeight > clientHeight) {
          // console.log("overflowing");
        } else {
          console.log("오버플로우 안함");
          const remainHeight = clientHeight - 961 + 80;
          console.log(`남는 높이 = ${remainHeight}`);
          if (remainHeight > menuHeight) {
            setMenuPlacement("bottom");
          } else {
            setMenuPlacement("top");
          }
        }
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow); // 창 크기가 변경될 때마다 체크
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);
  const { t } = useTranslation();
  const [columnWidths, setColumnWidths] = useState({
    col1: 133, //54
    col2: 291, //127
    col3: 267, //109
    col4: 189, //70
    col5: 213, //82
    col6: 95, //1 기존 -> 51
    col7: 237, //100
    col8: 135, //24
  });
  const [tableWidths, setTableWidths] = useState();
  const [tableHeights, setTableHeights] = useState(793);

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // 자식 부모보다 높고 상위 부모보다 낮은 z-index 설정
    }),
    control: (base) => ({
      ...base,
      border: "solid 1px #e9e7fd", // 외곽선
      backgroundColor: "white", // 배경색
      width: "78px", // 너비
      height: "40px", // 높이 (기존 select에 맞추기 위해 설정)
      padding: "0", // 패딩
      fontSize: "15px", // 글씨 크기
      fontWeight: "500", // 글씨 두께
      borderRadius: "6px", // 테두리 둥글기
      fontFamily: "publicSans", // 폰트 설정
      alignItems: "center",
      justifyContent: "center",
    }),

    singleValue: (provided) => ({
      ...provided,
      width: "100%", // 텍스트가 가운데 정렬되도록 너비를 100%로 설정
    }),
    menu: (provided) => ({
      ...provided,
      ...provided,
      zIndex: 9999,
      left: 0, // 부모 요소의 왼쪽에 맞춤
    }),
    valueContainer: (base) => ({
      ...base,
      justifyContent: "right",
      padding: "3px 0px 0px 0px", // 텍스트 주변의 패딩 조정
      margin: "0px 0px 0px 0px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: "0px", // 인디케이터와 텍스트 사이의 패딩 제거
    }),
    indicatorSeparator: () => ({
      display: "none", // 인디케이터와 텍스트 사이의 구분선을 없앰
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0px 0px 0px 0px", // 드롭다운 인디케이터의 패딩을 없앰
      margin: "0px 20px 0px 0px", // 드롭다운 인디케이터의 마진을 없앰
      width: "16px",
      height: "16px",
    }),
  };
  const dispatch = useDispatch();
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={showingDownImg} // 원하는 이미지 경로
          alt="Show options"
        />
      </components.DropdownIndicator>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: t("main.content.column.id"),
        accessor: "order",
        // Cell: ({ row }) => row.index + 1,
        width: columnWidths.col1,
      },
      {
        Header: t("main.content.column.model"),
        accessor: "prodName",
        width: columnWidths.col2,
      },
      {
        Header: t("main.content.column.reg_number"),
        accessor: "regCode",
        width: columnWidths.col3,
      },
      {
        Header: t("main.content.column.device_serial"),
        accessor: "serial",
        width: columnWidths.col4,
      },
      {
        Header: t("main.content.column.device_state.label"),
        accessor: "deviceState",
        width: columnWidths.col5,
        Cell: ({ value }) => {
          // value는 해당 셀의 데이터 (device_state 값)
          return (
            <div className="flex items-center justify-center">
              {value === "ok" ? (
                <div className="flex items-center justify-center h-[30px] w-[77px] rounded-[6px] bg-device_state-ok">
                  <span className="text-device_state-text-ok text-[12px] font-bold">
                    {t("main.content.column.device_state.ok")}
                  </span>
                </div>
              ) : value === "failure" ? (
                <div className="flex items-center justify-center h-[30px] w-[77px] rounded-[6px] bg-device_state-failure">
                  <span className="text-device_state-text-failure text-[12px] font-bold">
                    {t("main.content.column.device_state.failure")}
                  </span>
                </div>
              ) : value === "dispose" ? (
                <div className="flex items-center justify-center h-[30px] w-[77px] rounded-[6px] bg-device_state-dispose">
                  <span className="text-device_state-text-dispose text-[12px] font-bold">
                    {t("main.content.column.device_state.dispose")}
                  </span>
                </div>
              ) : (
                <span>{value}</span>
              )}
            </div>
          );
        },
      },
      {
        Header: t("main.content.column.connection_state"),
        accessor: "isConnect",
        width: columnWidths.col6,
        Cell: ({ value }) => {
          return (
            <div className="flex items-center justify-center">
              {value ? (
                <img
                  src={onlineImg}
                  alt="online"
                  className="w-[30px] h-[30px]"
                />
              ) : (
                <img
                  src={offlineImg}
                  alt="offline"
                  className="w-[30px] h-[30px]"
                />
              )}
            </div>
          );
        },
      },
      {
        Header: t("main.content.column.reg_date"),
        accessor: "registeredAt",
        width: columnWidths.col7,
      },
      {
        Header: t("main.content.column.state_change"),
        accessor: "stateChange",
        width: columnWidths.col8,
        Cell: ({ row }) => {
          return (
            <div className="w-full flex items-center justify-center">
              <div className=" flex items-center justify-center w-[76px] h-[30px] justify-between">
                <button onClick={() => handleOnChangeDeviceState(row.cells)}>
                  <img
                    src={editImg}
                    alt="online"
                    className="w-[30px] h-[30px]"
                  />
                </button>

                <button onClick={() => handleOnDeleteDevice(row.cells)}>
                  <img
                    src={deleteImg}
                    alt="offline"
                    className="w-[30px] h-[30px]"
                  />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
    [t, columnWidths]
  );

  useEffect(() => {
    const totalWidth = Object.values(columnWidths).reduce(
      (acc, width) => acc + width,
      0
    );
    setTableWidths(totalWidth); // 전체 너비 계산 후 상태 업데이트
  }, [columnWidths]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // 현재 페이지 데이터
    canPreviousPage, // 이전 페이지가 있는지 여부
    canNextPage, // 다음 페이지가 있는지 여부
    pageOptions, // 전체 페이지 목록
    nextPage, // 다음 페이지로 이동
    previousPage, // 이전 페이지로 이동
    gotoPage, // 특정 페이지로 이동
    setPageSize, // 페이지 당 데이터 수 설정
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );
  // const prevPageRef = useRef(page); // 이전 page를 추적
  // const prevPageSizeRef = useRef(pageSize);
  const pageMaxSize = 5;
  const startPage = Math.floor(pageIndex / pageMaxSize) * pageMaxSize; // 보여줄 페이지 번호 범위의 시작 값
  const endPage = Math.min(startPage + pageMaxSize, pageOptions.length);

  useEffect(() => {
    const totalWidth = Object.values(columnWidths).reduce(
      (acc, width) => acc + width,
      0
    );
    setTableWidths(totalWidth); // 전체 너비 계산 후 상태 업데이트
  }, [columnWidths]);

  useEffect(() => {
    const totalHeight = page.reduce((acc) => acc + 63, 163);

    if (793 < totalHeight) {
      setTableHeights(totalHeight);
    } else {
      setTableHeights(793);
    }
  }, [page, pageSize]);

  const [isHovered, setIsHovered] = useState(false);
  // 마우스 뗐을 때
  const handleMouseEnter = () => {
    setIsHovered(true); // 마우스 올렸을 때
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const isTableLoading = useSelector((state) => state.common.tableIsLoading);

  const handleOnChangeDeviceState = useCallback(
    (cells) => {
      const selectedData = cells
        .slice(0, cells.length - 1)
        .map((cell) => cell.value);
      dispatch(deviceActions.setIsOpen({ location: "modify", isOpen: true }));

      const foundData = data.find((item) => {
        return item.serial === selectedData[3];
      });

      dispatch(deviceActions.getModifyDialogData(foundData));
    },
    [data, dispatch]
  );
  const handleOnDeleteDevice = async (cells) => {
    const selectedData = cells
      .slice(0, cells.length - 1)
      .map((cell) => cell.value);
    dispatch(dialogActions.setDeleteCheckIsOpen(true));

    const foundData = data.find((item) => {
      return item.serial === selectedData[3];
    });
    dispatch(deviceActions.setSelectedDeviceId(foundData.id));
  };

  return (
    <main
      ref={mainRef}
      className="bg-primary-200 w-full h-full pl-[78px] pr-[64px] pb-[50px] bg-opacity-30 grid grid-rows-[auto_1fr] overflow-auto z-5"
    >
      <div className="h-[118px] w-full flex items-center">
        <p className="text-[38px] font-bold text-primary-700">
          {t("main.content.title")}
        </p>
      </div>
      <div
        className={`relative rounded-[14px] flex flex-col justify-between overflow-hidden`}
        style={{
          border: "solid 0.6px #b9b9b9",
          borderColor: "rgba(0, 0, 0, 0.1)",
          width: `${tableWidths}px`,
          height: `${tableHeights}px`,
        }}
      >
        <table
          {...getTableProps()}
          style={{
            width: `${tableWidths}px`,
            borderTopLeftRadius: "14px", // 왼쪽 위 모서리 둥글게 설정
            borderTopRightRadius: "14px", // 오른쪽 위 모서리 둥글게 설정
            overflow: "hidden",
          }}
        >
          <thead
            className={`h-[49px] bg-[#fcfdfd]`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {headerGroups.map((headerGroup) => {
              const { key } = headerGroup.getHeaderGroupProps();

              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                  {headerGroup.headers.map((column, index) => {
                    const { key } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );
                    return (
                      <ResizableHeader
                        key={key}
                        width={columnWidths[`col${index + 1}`]}
                        onResize={(e, { size }) => {
                          setColumnWidths((prev) => {
                            return {
                              ...prev,
                              [`col${index + 1}`]: size.width,
                            };
                          });
                        }}
                        getSortByToggleProps={column.getSortByToggleProps}
                        isHovered={isHovered}
                      >
                        {column.render("Header")}

                        <span className="ml-[3px]">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className="inline-block w-[12px] h-[12px]">
                                <img
                                  src={downImg}
                                  className="w-full h-full"
                                  alt="sort_down"
                                />
                              </span>
                            ) : (
                              <span className="inline-block w-[12px] h-[12px]">
                                <img
                                  src={upImg}
                                  className="w-full h-full" // 이미지에 margin-right 추가
                                  alt="sort_up"
                                />
                              </span>
                            ) // 오름차순일 때 아이콘
                          ) : isHovered ? (
                            <span>-</span>
                          ) : (
                            <span></span>
                          )}
                        </span>
                      </ResizableHeader>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isTableLoading ? (
              <tr>
                <td>
                  <LoadingIndicator isLoading={isTableLoading} />
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td
                  colSpan={headerGroups[0].headers.length}
                  className="h-[461px] "
                >
                  <div className="flex justify-center items-end w-full h-full">
                    <div className="flex flex-col items-center justify-center gap-[16px]">
                      <img
                        src={emptyTableImg}
                        className="w-[113px] h-[113px]"
                        alt="empty table"
                      />
                      <p className="text-[24px] font-[500] text-gray-300">
                        {t("main.content.no_content")}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        key={cell.column.id}
                        className="text-center h-[63px]"
                        style={{
                          width: columnWidths[cell.column.id],
                          borderBottom: "0.6px solid #b9b9b9",
                          borderColor: "rgba(0, 0, 0, 0.1)",
                        }} // 각 셀의 너비를 독립적으로 설정
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>

        {data.length < 11 ? (
          <div className="h-[112px]"></div>
        ) : (
          <div className="pagination h-[112px] mt-auto pt-[26px] pl-[24px] pb-[16px] overflow-visible relative z-4">
            <div className="flex itmes-center h-full justify-between relative z-4">
              <span className="flex items-center gap-[8px] relative z-4">
                <p className="font-publicSans text-[15px] font-[500] text-[#8b909a] tracking-custom">
                  {t("main.content.column.showing")}
                </p>
                <Select
                  id="select-component"
                  //ref={selectRef}
                  defaultValue={{ value: pageSize, label: pageSize }}
                  onChange={(selectedOption) =>
                    setPageSize(selectedOption.value)
                  }
                  // onFocus={() => {
                  //   console.log("포커스 됨");
                  //   handleSelectFocus();
                  // }} // 포커스 이벤트 추가
                  isClearable={false}
                  isSearchable={false}
                  onMenuOpen={handleSelectFocus}
                  //menuPlacement="auto" // 메뉴를 위나 아래로 자동으로 배치
                  //menuPosition="fixed" // 고정된 위치에 메뉴 배치
                  menuPlacement={menuPlacement}
                  options={showingList.map((size) => ({
                    value: size,
                    label: size,
                  }))}
                  styles={customStyles}
                  components={{
                    DropdownIndicator,
                  }} // CustomMenu 추가
                  menuPortalTarget={document.body}
                  menuRef={menuRef} // menuRef로 메뉴 DOM 노드 연결
                />

                <p className="font-publicSans text-[15px] font-[500] text-[#8b909a] tracking-custom">
                  of {rows.length}
                </p>
              </span>
              <div className="flex items-center gap-[2px] mr-[24px]">
                <ButtonTablePage
                  disabled={!canPreviousPage}
                  onClick={() => previousPage()}
                >
                  <img
                    className="w-[16px] h-[16px]"
                    src={tableLeft}
                    alt="tableLeft"
                  />
                </ButtonTablePage>

                {pageOptions.slice(startPage, endPage).map((value) => {
                  return (
                    <ButtonTablePage
                      key={value}
                      style={{
                        backgroundColor: value === pageIndex ? "#0f60ff" : "",
                        color: value === pageIndex ? "#fff" : "#8b909a",
                        boxShadow:
                          value === pageIndex
                            ? "0 2px 4px 0 rgba(165, 163, 174, 0.3)"
                            : "",
                      }}
                      onClick={() => gotoPage(value)} // 페이지 번호 클릭 시 해당 페이지로 이동
                    >
                      {value + 1}
                    </ButtonTablePage>
                  );
                })}

                <ButtonTablePage
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <img
                    className="w-[16px] h-[16px]"
                    src={tableRight}
                    alt="tableRight"
                  />
                </ButtonTablePage>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}

const ResizableHeader = ({
  children,
  onResize,
  width,
  getSortByToggleProps,
  isHovered,
}) => {
  const [isResizing, setIsResizing] = useState(false);

  const handleResizerClick = (e) => {
    e.stopPropagation(); // 이벤트 전파 방지
  };

  return (
    <Resizable
      width={width}
      height={0}
      axis="x"
      handle={
        <span
          className={`resizer`} // 상태에 따라 표시
          onClick={handleResizerClick}
        />
      }
      onResize={onResize}
      onResizeStart={() => setIsResizing(true)} // 리사이징 시작 시
      onResizeStop={() => setIsResizing(false)} // 리사이징 종료 시
    >
      <th
        {...getSortByToggleProps()}
        style={{
          width: `${width}px`,
          position: "relative", // 리사이저 핸들의 위치 설정
          borderBottom: "0.6px solid #b9b9b9",
          borderColor: "rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          color: "#202224",
        }}
        className="text-[14px] group"
      >
        {children}

        <span
          className={`resizer h-[19px] rounded-[15px] group:opacity-100`} // 상태에 따라 resizer 표시
          style={{
            position: "absolute",
            right: 4,
            top: 15,
            bottom: 0,
            width: "3px",
            cursor: "col-resize",
            opacity: 0.4,
            backgroundColor: `${isHovered ? "#7D7D7D" : ""}`, //"rgba(0, 0, 0, 0.1)", // 기본적으로는 투명
            transition: "background-color 0.2s",
          }}
        />
      </th>
    </Resizable>
  );
};
