import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/authSlice";
import { login } from "../../api/auth";
import Button from "../UI/Button";
import { useTranslation } from "react-i18next";
import { redirect, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../api/customer";
import { commonActions } from "../../store/commonSlice";
import "../UI/Spinner.css";
import ErrorDescription from "../UI/ErrorDescription";

export default function Input({ placeholder, icon, children, authStep }) {
  const emailState = useSelector((state) => state.auth.emailState);
  const codeState = useSelector((state) => state.auth.codeState);
  const inputFocus = useSelector((state) => state.auth.focus);
  const [isSubmit, setIsSubmit] = useState(false);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.common.isLoading);
  const termsIsChecked = useSelector((state) => state.auth.terms.isChecked);

  function handleFocus() {
    setIsSubmit(false);
    dispatch(authActions.setInputFocus({ value: true, authStep }));
  }
  function handleBlur() {
    const value = inputRef.current.value;
    if (authStep === "email") {
      dispatch(authActions.setIsVerified({ value, authStep }));
    }
    dispatch(authActions.setInputFocus({ value: false, authStep }));
  }

  function handleOnChange(event) {
    const value = event.target.value;
    dispatch(authActions.setInputData({ value: value.trim(), authStep }));
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);

    const value = inputRef.current.value;

    try {
      if (authStep === "email") {
        dispatch(authActions.setIsVerified({ value, authStep }));
        if (!inputFocus && emailState.isVerified && emailState.email !== "") {
          dispatch(commonActions.setIsLoading(true));
          const response = await verifyEmail({ email: value });
          dispatch(commonActions.setIsLoading(false));
          console.log("이메일 코드 전송");
          if (response) {
            console.log("성공");
            setIsSubmit(false);
            dispatch(authActions.reset({ identifier: "code" }));
            navigate("../code");
          } else {
            console.log("실패");
          }
        }
      } else if (authStep === "code") {
        if (!inputFocus && codeState.code !== "") {
          //Todo : 다음 스텝
          dispatch(commonActions.setIsLoading(true));
          const response = await login({
            email: emailState.email,
            verifyCode: value,
          });
          dispatch(commonActions.setIsLoading(false));

          if (response) {
            console.log("nextpage");
            dispatch(authActions.setIsVerified({ value: true, authStep }));
            //navigate("/home");
            navigate("/", { replace: true });
            //window.location.href = "/home";
          } else {
            dispatch(authActions.setIsVerified({ value: false, authStep }));
          }
        }
      }
    } catch (error) {
      console.error("에러발생");
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <div className="relative">
          <div className="relative">
            <div className="flex items-center">
              {icon && <span className="absolute left-[20px]">{icon}</span>}
              <input
                type="text"
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={inputRef}
                onChange={handleOnChange}
                value={authStep === "email" ? emailState.email : codeState.code}
                className={`w-full pr-[45px] text-[18px] py-[16px] rounded-md focus:outline-none placehor:text-gray8b text-gray3c ${(authStep === "email" ? emailState.inputIsValid : codeState.inputIsValid) ? " bg-white ring-2 ring-primary-400" : "bg-gray-100"} ${icon ? "pl-[65px]" : "pl-[45px]"}`}
              />
            </div>
          </div>
          <div></div>
          {authStep === "email"
            ? !inputFocus &&
              emailState.email !== "" &&
              isSubmit &&
              !emailState.isVerified && (
                <ErrorDescription
                  description={t("login.email.error_description")}
                />
              )
            : !inputFocus &&
              codeState.code !== "" &&
              isSubmit &&
              !codeState.isVerified && (
                <ErrorDescription
                  description={t("verify_code.code.error_description")}
                />
              )}
        </div>
      )}
      {children}
      <Button
        onClick={handleSubmit}
        isEntered={
          (authStep === "email" ? emailState.email : codeState.code).trim() !==
            "" && termsIsChecked
        }
      >
        {t("button.next")}
      </Button>
    </>
  );
}
