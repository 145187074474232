import axios from "axios";
import { getAccessToken, saveEmail, saveTokens } from "../store/token";
import { api } from "./base";
import { errorCatch } from "./common";

export const login = async ({ email, verifyCode, isTest }) => {
  try {
    const requestBody = {
      email,
      verifyCode,
    };
    console.log("requestBody", requestBody);
    const response = isTest
      ? await axios.post("/customer/login", requestBody)
      : await api.post("/customer/login", requestBody);
    if (response) {
      const { id, tokens } = response.data;

      console.log("사용자 ID:", id);
      console.log("Access Token:", tokens.accessToken);
      console.log("Refresh Token:", tokens.refreshToken);

      saveTokens(tokens);
      saveEmail(email);

      return true;
    } else {
      console.log("response", response);
      console.log("토큰 저장 실패");
      return false;
    }
  } catch (error) {
    //console.error(error);
    errorCatch(error);
    return false;
  }
};
export const refreshLogin = async ({ refreshToken, isTest }) => {
  try {
    const requestBody = {
      refreshToken,
    };
    const response = isTest
      ? await axios.post("/auth/refresh", requestBody)
      : await api.post("/auth/refresh", requestBody);
    const tokens = response.data;
    saveTokens(tokens);
    /// response는
    // {
    //   "tokenType": "string",
    //   "accessToken": "string",
    //   "expiresIn": 0,
    //   "refreshToken": "string"
    // }

    return true;
  } catch (error) {
    errorCatch(error);
    return false;
  }
};
