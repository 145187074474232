import { createSlice } from "@reduxjs/toolkit";

const dialogInitialState = {
  emailQuestionIsOpen: false,

  isEmailFocus: false,
  isContentFocus: false,
  email: "",
  content: "",
  isVisibleContent: false,
  isVisibleEmail: false,
  emailIsVerified: undefined,
};

const inquirySlice = createSlice({
  name: "dialog",
  initialState: dialogInitialState,
  reducers: {
    resetAll(state) {
      return dialogInitialState;
    },
    setEmailQuestionIsOpen(state, action) {
      state.emailQuestionIsOpen = action.payload;
    },
    setIsVerified(state, action) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      state.emailIsVerified = emailPattern.test(action.payload);
    },
    setIsFocus(state, action) {
      if (action.payload.identifier === "email") {
        state.isEmailFocus = action.payload.isFocus;
        state.isVisibleEmail = state.email.trim() != "" || state.isEmailFocus;
      } else if (action.payload.identifier === "content") {
        state.isContentFocus = action.payload.isFocus;
        state.isVisibleContent =
          state.content.trim() != "" || state.isContentFocus;
      } else {
        state.isContentFocus = false;
        state.isEmailFocus = false;
        state.isVisibleContent =
          state.content.trim() != "" || state.isContentFocus;
        state.isVisibleEmail = state.email.trim() != "" || state.isEmailFocus;
      }
    },
    setContent(state, action) {
      state.content = action.payload;
      state.isVisibleContent =
        state.content.trim() != "" || state.isContentFocus;
    },
    setEmail(state, action) {
      state.email = action.payload;
      state.isVisibleEmail = state.email.trim() != "" || state.isEmailFocus;
    },
  },
});

export default inquirySlice.reducer;
export const inquiryActions = inquirySlice.actions;
