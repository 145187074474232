import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  terms: {
    isChecked: false,
    isOpen: false,
  },
  emailState: {
    email: "",
    isVerified: undefined,
    inputIsValid: false,
  },
  newEmailState: {
    newEmail: "",
    isVerified: undefined,
    changeEmailDialog: {
      isOpen: false,
    },
    changeEmailVerifyDialog: {
      isOpen: false,
    },
  },
  newCodeState: {
    isVerified: false,
  },
  codeState: {
    code: "",
    isVerified: false,
    inputIsValid: false,
  },
  focus: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    resetAll(state) {
      // 전체 상태를 초기 상태로 리셋
      return initialState; // 초기 상태로 전체 리셋
    },
    reset(state, action) {
      if (action.payload.identifier === "email") {
        state.emailState.email = "";
        state.emailState.inputIsValid = false;
        state.emailState.isVerified = undefined;
        state.terms.isChecked = false;
        state.focus = false;
      } else if (action.payload.identifier === "code") {
        state.codeState.code = "";
        state.codeState.isVerified = false;
        state.codeState.inputIsValid = false;
        state.focus = false;
      } else if (action.payload.identifier === "newEmail") {
        state.newEmailState.newEmail = "";
        state.newEmailState.isVerified = undefined;
      } else if (action.payload.identifier === "newCode") {
        state.newCodeState.isVerified = false;
      }
    },
    nextStep(state) {
      state.focus = false;
      state.codeState.isVerified = true;
      state.codeState.code = "";
    },
    setIsTermsChecked(state) {
      state.terms.isChecked = !state.terms.isChecked;
    },
    setEmail(state, action) {
      state.emailState.email = action.payload;
    },
    setInputData(state, action) {
      if (action.payload.authStep === "email") {
        state.emailState.email = action.payload.value;
        state.emailState.inputIsValid =
          state.focus || state.emailState.email.trim() !== "";
      } else if (action.payload.authStep === "code") {
        state.codeState.code = action.payload.value;
        state.codeState.inputIsValid =
          state.focus || state.codeState.code.trim() !== "";
      }
    },
    setIsVerified(state, action) {
      if (action.payload.authStep === "email") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        state.emailState.isVerified = emailPattern.test(action.payload.value);
      } else if (action.payload.authStep === "code") {
        state.codeState.isVerified = action.payload.value;
      } else if (action.payload.authStep === "newEmail") {
        state.newEmailState.isVerified = false;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        state.newEmailState.isVerified = emailPattern.test(
          action.payload.value
        );
      } else if (action.payload.authStep === "newCode") {
        state.newCodeState.isVerified = action.payload.value;
      }
    },
    setVerificationCode(state, action) {
      state.codeState.verificationCode = action.payload;
    },
    setInputFocus(state, action) {
      state.focus = action.payload.value;

      if (action.payload.authStep === "email") {
        state.emailState.inputIsValid =
          state.focus || state.emailState.email.trim() !== "";
      } else if (action.payload.authStep === "code") {
        state.codeState.inputIsValid =
          state.focus || state.codeState.code.trim() !== "";
      }
    },
    setTermsIsOpen(state, action) {
      state.terms.isOpen = action.payload;
    },
    setChangeEmailIsOpen(state, action) {
      state.newEmailState.changeEmailDialog.isOpen = action.payload;
    },
    setChangeEmailVerifyIsOpen(state, action) {
      state.newEmailState.changeEmailVerifyDialog.isOpen = action.payload;
    },
    setNewEmail(state, action) {
      state.newEmailState.newEmail = action.payload;
    },
    resetIsVerified(state, action) {
      if (action.payload === "email") {
        state.emailState.isVerified = false;
      } else if (action.payload === "code") {
        state.codeState.isVerified = false;
      } else if (action.payload === "newEmail") {
        state.newEmailState.isVerified = false;
      } else if (action.payload === "newCode") {
        state.newCodeState.isVerified = false;
      }
    },
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;
