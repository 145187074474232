import React, { useRef, useEffect } from "react";
import downImg from "../../assets/icons/down.png";
import upImg from "../../assets/icons/up.png";
import checkImg from "../../assets/icons/check.png";
import { useDispatch, useSelector } from "react-redux";
import { selectActions } from "../../store/selectSlice";
import i18n from "../../i18n";

export default function SelectLanguage({ options }) {
  const isOpen = useSelector((state) => state.select.isOpen);
  const selectedLanguage = useSelector(
    (state) => state.select.selectedLanguage
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const dispatch = useDispatch();
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    dispatch(selectActions.openSelect());
  };
  const handleSelect = (key, value) => {
    changeLanguage(key);
    dispatch(selectActions.selectLanguage(value));
    dispatch(selectActions.openSelect());
  };

  const handleClickOutside = (event) => {
    if (
      selectRef.current &&
      dropdownRef.current &&
      !selectRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      dispatch(selectActions.openSelect());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <button
        className="appearance-none rounded-[10px] border-2 border-primary-400 h-full w-full px-[8px] ml-[17px] flex justify-between items-center bg-white"
        onClick={handleToggle}
        ref={selectRef}
      >
        <span className="flex-grow text-start text-gray30">
          {selectedLanguage || options.ko}
        </span>
        <img
          src={isOpen ? upImg : downImg}
          alt="Dropdown Arrow"
          className="w-[30px] h-[30px]"
        />
      </button>
      {isOpen && (
        <div
          className="absolute top-full left-[36px] mt-1 w-[calc(100%-36px)] bg-white border border-gray-300 rounded-lg shadow-lg z-10"
          ref={dropdownRef}
        >
          <ul className="list-none m-0 p-0">
            {Object.entries(options).map(([key, value]) => (
              <li
                key={key}
                className={`px-[12px] hover:bg-primary-200 cursor-pointer my-[8px] hover:outline outline-2 outline-primary-400 mx-[4px] rounded-[10px] ${selectedLanguage === value && "bg-primary-300"}`} //
                onClick={() => {
                  handleSelect(key, value);
                }}
              >
                <div className="flex items-center h-[38px] justify-between">
                  {value}
                  {selectedLanguage === value && (
                    <img src={checkImg} className="w-[30px] h-[30px]" />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
