import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dialogActions } from "../../store/dialogSlice";
import Modal from "../UI/Modal";
import NoticeForm from "./NoticeForm";
import warnningRedImg from "../../assets/icons/warnningRed.png";
import LoadingIndicator from "./LoadingIndicator";
import { useEffect } from "react";
import { commonActions } from "../../store/commonSlice";
import { deleteDevice, getDevices } from "../../api/customerDevice";
import { deviceActions } from "../../store/deviceSlice";

export default function DeleteCheckDialog() {
  const deleteCheckDialogIsOpen = useSelector(
    (state) => state.dialog.deleteCheck.isOpen
  );
  const selectedDeviceId = useSelector(
    (state) => state.device.selectedDeviceId
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  function handleCancel() {
    dispatch(dialogActions.setDeleteCheckIsOpen(false));
  }
  const handleConfirm = async () => {
    //Todo 삭제 api

    // const selectedState = checkDeviceState();
    dispatch(commonActions.setIsDialogLoading(true));

    const response = await deleteDevice({
      id: selectedDeviceId,
      isTest: false,
    });

    dispatch(commonActions.setIsDialogLoading(false));
    if (response) {
      console.log("기기 삭제 완료");

      dispatch(dialogActions.setDeleteCheckIsOpen(false));
      dispatch(dialogActions.setDeleteCompleteIsOpen(true));
      const fetchDevices = async () => {
        dispatch(commonActions.setIsTableLoading(true));
        const response = await getDevices({ isTest: false });
        dispatch(commonActions.setIsTableLoading(false));
        if (response) {
          console.log("Devices 호출 성공", response);
          const data = response.content;
          if (data) {
            dispatch(deviceActions.setDevices(data));

            // console.log("저장된 기기들", data);
          } else {
            console.log("기기 없음");
          }
        } else {
          console.log("Devices 호출 실패", response);
        }
      };
      fetchDevices();
    } else {
      alert("취소");
      handleCancel();
    }
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" || event.key === "Enter") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (deleteCheckDialogIsOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [deleteCheckDialogIsOpen]);
  return (
    <Modal
      open={deleteCheckDialogIsOpen}
      className={"modal w-[476px] h-[266px] modal_notice"}
    >
      <NoticeForm
        icon={<img src={warnningRedImg} alt={"complete"} />}
        title={t("delete_dialog.title")}
        isWarnning
        description={t("delete_dialog.description")}
        descriptionNext={t("delete_dialog.description_next")}
        isOneButton={false}
        confirmTitle={t("button.confirm")}
        cancelTitle={t("button.cancel")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </Modal>
  );
}
