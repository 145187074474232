import Cookies from "js-cookie";

export const saveTokens = (tokens) => {
  Cookies.set("accessToken", tokens.accessToken, {
    secure: true,
    sameSite: "strict",
  });
  Cookies.set("refreshToken", tokens.refreshToken, {
    secure: true,
    sameSite: "strict",
  });
};

export const saveEmail = (email) => {
  Cookies.set("email", email, {
    secure: true,
    sameSite: "strict",
  });
};

export const getAccessToken = () => {
  const token = Cookies.get("accessToken");
  return token;
};
export const getEmail = () => {
  const email = Cookies.get("email");
  return email;
};

export const getRefreshToken = () => {
  const token = Cookies.get("refreshToken");
  return token;
};

export const removeTokens = () => {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  Cookies.remove("email");
};
