import axios from "axios";
import { getAccessToken } from "../store/token";
import { api } from "./base";
import { errorCatch } from "./common";

export const verifyEmail = async ({ email, isTest }) => {
  try {
    const requestBody = {
      email,
    };
    const response = isTest
      ? await axios.post("/customer/verify", requestBody)
      : await api.post("/customer/verify", requestBody);

    return true;
  } catch (error) {
    // console.error("이메일 인증 중 오류 발생:", error);
    errorCatch(error);
    return false;
  }
};

export const changeEmail = async ({ email, verifyCode }) => {
  try {
    const requestBody = {
      email,
      verifyCode,
    };
    const token = getAccessToken();
    const response = await api.patch("/customer", requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error("이메일 인증 중 오류 발생:", error);
    errorCatch(error);
    return false;
  }
};
