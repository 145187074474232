import { createSlice } from "@reduxjs/toolkit";

export const LANGUAGE = {
  ko: "한국어",
  // en: "English",
  //   es: "Spanish",
  //   ch: "中文",
  //   ja: "日本语",
};

const selectInitial = { selectedLanguage: LANGUAGE.ko, isOpen: false };

const selectSlice = createSlice({
  name: "select",
  initialState: selectInitial,
  reducers: {
    selectLanguage(state, action) {
      state.selectedLanguage = action.payload;
    },
    openSelect(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export default selectSlice.reducer;
export const selectActions = selectSlice.actions;
