import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dialogActions } from "../../store/dialogSlice";
import Modal from "../UI/Modal";
import NoticeForm from "./NoticeForm";
import checkCircleImg from "../../assets/icons/check-circle.png";
import LoadingIndicator from "./LoadingIndicator";
import { useEffect } from "react";

export default function RegistCompleteDialog() {
  const registCompleteIsOpen = useSelector(
    (state) => state.dialog.registComplete.isOpen
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleConfirm() {
    dispatch(dialogActions.setRegistCompleteIsOpen(false));
  }
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (registCompleteIsOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [registCompleteIsOpen]);
  return (
    <Modal
      open={registCompleteIsOpen}
      className={"modal w-[476px] h-[266px] modal_notice"}
    >
      <NoticeForm
        icon={<img src={checkCircleImg} alt={"complete"} />}
        title={t("regist_device_dialog.conf_noti")}
        isOneButton={true}
        confirmTitle={t("button.confirm")}
        handleConfirm={handleConfirm}
      />
    </Modal>
  );
}
