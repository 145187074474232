import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dialogActions } from "../../store/dialogSlice";
import Modal from "../UI/Modal";
import NoticeForm from "./NoticeForm";
import warnningImg from "../../assets/icons/warnning.png";
import LoadingIndicator from "./LoadingIndicator";
import { useEffect } from "react";
import { commonActions } from "../../store/commonSlice";
import { deleteDevice, getDevices } from "../../api/customerDevice";
import { deviceActions } from "../../store/deviceSlice";
import { removeTokens } from "../../store/token";
import { useNavigate } from "react-router-dom";

export default function LogoutCheckDialog({ onConfirm }) {
  const isOpen = useSelector((state) => state.dialog.logoutCheck.isOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleCancel() {
    dispatch(dialogActions.setLogoutCheckIsOpen(false));
  }
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" || event.key === "Enter") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen]);
  return (
    <Modal open={isOpen} className={"modal w-[476px] h-[210px] modal_notice"}>
      <NoticeForm
        icon={<img src={warnningImg} alt={"complete"} />}
        title={t("logout_dialog.description")}
        isOneButton={false}
        confirmTitle={t("button.confirm")}
        cancelTitle={t("button.cancel")}
        handleConfirm={onConfirm}
        handleCancel={handleCancel}
      />
    </Modal>
  );
}
