import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import "./App.css";

import HomePage from "./pages/HomePage.jsx";
import { getAccessToken, getEmail } from "./store/token.jsx";
import SendCompleteDialog from "./components/dialogs/SendComplete.jsx";
import DeviceRegistrationDialog from "./components/dialogs/DeviceRegistration.jsx";
import AuthCodePage from "./pages/AuthCodePage.jsx";
import AuthEmailPage from "./pages/AuthEmailPage.jsx";
import TermsDialog from "./components/dialogs/Terms.jsx";
import InquiryDialog from "./components/dialogs/Inquiry.jsx";
import RegistCompleteDialog from "./components/dialogs/RegistComplete.jsx";
import DeviceModificationDialog from "./components/dialogs/DeviceModification.jsx";
import ModifyCompleteDialog from "./components/dialogs/ModifyComplete.jsx";
import DeleteCheckDialog from "./components/dialogs/DeleteCheck.jsx";
import DeleteCompleteDialog from "./components/dialogs/DeleteComplete.jsx";
import ChangeEmailDialog from "./components/dialogs/ChangeEmail.jsx";
import ChangeEmailVerifyDialog from "./components/dialogs/ChangeEmailVerify.jsx";
import ChangeEmailComplete from "./components/dialogs/ChangeEmailComplete.jsx";
import LogoutCheckDialog from "./components/dialogs/LogoutCheck.jsx";
import LogoutCompleteDialog from "./components/dialogs/LogoutComplete.jsx";
import { useDispatch } from "react-redux";
import { authActions } from "./store/authSlice.jsx";
import NotFoundPage from "./pages/NotFoundPage.jsx";

function App() {
  const dispatch = useDispatch();
  const checkAuth = () => {
    const isAuthenticated = getAccessToken();

    if (!isAuthenticated || isAuthenticated === undefined) {
      console.log("되돌아가기");
      dispatch(authActions.resetAll());
      return redirect("/login");
    }
    return null;
  };
  const email = getEmail();
  if (email) {
    dispatch(authActions.setEmail(email));
  }

  const router = createBrowserRouter([
    { path: "/login", element: <AuthEmailPage /> },
    { path: "/code", element: <AuthCodePage /> },
    { path: "/", element: <HomePage />, loader: checkAuth },
    { path: "*", element: <NotFoundPage /> },
  ]);
  return (
    <>
      <InquiryDialog />
      <SendCompleteDialog />
      <DeviceRegistrationDialog />
      <TermsDialog />
      <RegistCompleteDialog />
      <ModifyCompleteDialog />
      <DeviceModificationDialog />
      <DeleteCheckDialog />
      <DeleteCompleteDialog />
      <ChangeEmailDialog />
      <ChangeEmailVerifyDialog />
      <ChangeEmailComplete />

      <LogoutCompleteDialog />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
