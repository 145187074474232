import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/Modal";
import { inquiryActions } from "../../store/inquirySlice";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { inquiry } from "../../api/customerHelp";
import { commonActions } from "../../store/commonSlice";
import { dialogActions } from "../../store/dialogSlice";
import LoadingIndicator from "./LoadingIndicator";

export default function InquiryDialog() {
  const emailRef = useRef();
  const contentRef = useRef();
  const dispatch = useDispatch();
  const emailIsVerified = useSelector((state) => state.inquiry.emailIsVerified);
  const [inputState, setInputState] = useState("");
  const [contentState, setContentState] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const isDialogLoading = useSelector((state) => state.common.isDialogLoading);
  const { t } = useTranslation();

  const isVisibleContent = useSelector(
    (state) => state.inquiry.isVisibleContent
  );
  const isVisibleEmail = useSelector((state) => state.inquiry.isVisibleEmail);

  const isEmailFocus = useSelector((state) => state.inquiry.isEmailFocus);

  function handleOnFocus(identifier) {
    dispatch(inquiryActions.setIsFocus({ identifier, isFocus: true }));
  }
  function handleOnBlur(identifier) {
    if (identifier === "email") {
      const value = emailRef.current.value;

      dispatch(inquiryActions.setIsVerified(value));
      dispatch(inquiryActions.setEmail(value));
    } else if (identifier === "content") {
      const value = contentRef.current.value;
      dispatch(inquiryActions.setContent(value));
    }
    dispatch(inquiryActions.setIsFocus({ identifier, isFocus: false }));
  }
  const emailQuestionIsOpen = useSelector(
    (state) => state.inquiry.emailQuestionIsOpen
  );

  function handleEmail(event) {
    setInputState(event.target.value);
  }

  function handleContent(event) {
    dispatch(inquiryActions.setContent(event.target.value));
    setContentState(event.target.value);
  }
  function handleCancel() {
    dispatch(inquiryActions.resetAll());
    setInputState("");
    setContentState("");
  }
  async function handleConfirm() {
    setIsSubmit(true);
    const email = emailRef.current.value;
    const message = contentRef.current.value;
    dispatch(inquiryActions.setIsVerified(email));

    if (!isEmailFocus && emailIsVerified && inputState !== "") {
      dispatch(commonActions.setIsDialogLoading(true));
      const response = await inquiry({ email, message, isTest: false });
      dispatch(commonActions.setIsDialogLoading(false));

      if (response) {
        dispatch(inquiryActions.resetAll());
        setContentState("");
        setInputState("");
        setIsSubmit(false);
        dispatch(dialogActions.setSendCompleteIsOpen(true));
      }
    }
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" || event.key === "Enter") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (emailQuestionIsOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [emailQuestionIsOpen]);
  return (
    <Modal
      open={emailQuestionIsOpen}
      className={`modal modal_input w-[610px] overflow-auto`}
    >
      <LoadingIndicator isLoading={isDialogLoading} />

      <div className="flex flex-col h-full z-5">
        <header className="px-[37px] pt-[28px] border-b-[0.5px] border-gray-400 border-opacity-50">
          <div className="flex items-center">
            <div className="w-[40px] h-[40px] flex items-center justify-center bg-primary-200 shadow-custom">
              <svg
                width="25px"
                height="25px"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 5H5C4.33696 5 3.70107 5.26339 3.23223 5.73223C2.76339 6.20107 2.5 6.83696 2.5 7.5V22.5C2.5 23.163 2.76339 23.7989 3.23223 24.2678C3.70107 24.7366 4.33696 25 5 25H25C25.663 25 26.2989 24.7366 26.7678 24.2678C27.2366 23.7989 27.5 23.163 27.5 22.5V7.5C27.5 6.83696 27.2366 6.20107 26.7678 5.73223C26.2989 5.26339 25.663 5 25 5ZM25 10.295L15 16.3975L5 10.295V7.5H25V10.295Z"
                  fill="#007dfa"
                />
              </svg>
            </div>
            <h2 className="pl-[11px] text-[24px] font-bold">
              {t("login.inquiry_dialog.title")}
            </h2>
          </div>
          <div className="h-[55.8px] flex items-center px-[20px]">
            <p className=" font-medium text-gray-400">
              {t("login.inquiry_dialog.description")}
            </p>
          </div>
        </header>
        <main className="relative flex-grow h-full flex flex-col">
          <div className="flex justify-center h-[149.8px] z-5">
            <div className="relative w-[504px] flex flex-col pt-[39.8px] pb-[35px]">
              <p className="text-primary-700 mb-[8px]">
                {t("login.inquiry_dialog.recipient")}
              </p>
              <input
                onFocus={() => handleOnFocus("email")}
                onBlur={() => handleOnBlur("email")}
                ref={emailRef}
                value={inputState}
                onChange={handleEmail}
                className={`${isVisibleEmail ? "bg-white outline outline-2 outline-primary-400" : "bg-gray-100"} w-full h-[48px] px-[16px] rounded-[10px] focus:outline-primary-400`}
                type="text"
                placeholder={t("login.inquiry_dialog.recipient_placeholder")}
              />
              {!isEmailFocus &&
                !emailIsVerified &&
                inputState !== "" &&
                isSubmit && (
                  <p className="absolute left-0 bottom-[5px] font-[300] text-[12px] text-negative-500">
                    {t("login.email.error_description")}
                  </p>
                )}
            </div>
          </div>
          <div className="flex-grow flex flex-col pl-[53px] pr-[40px] z-5">
            <div className="flex-grow flex flex-col w-full">
              <p className="text-primary-700 font-medium mb-[8px]">
                {t("login.inquiry_dialog.inquiry_content")}
              </p>
              <textarea
                maxLength={500}
                onFocus={() => handleOnFocus("content")}
                onBlur={() => handleOnBlur("content")}
                ref={contentRef}
                value={contentState}
                onChange={handleContent}
                className={`${isVisibleContent ? "bg-white outline outline-2 outline-primary-400" : "bg-gray-100"} flex-grow px-[16px] h-[470px] py-[14px] placeholder-gray8b rounded-[10px] focus:outline-primary-400`}
                placeholder={t(
                  "login.inquiry_dialog.inquiry_content_placeholder"
                )}
              />
              <div className="button-container-inquiry">
                <button
                  onClick={handleCancel}
                  className="common-button cancel-button"
                >
                  {t("button.cancel")}
                </button>
                <button
                  onClick={handleConfirm}
                  disabled={!(contentState && inputState !== "")}
                  className={`common-button confirm-button ${contentState && inputState !== "" ? "confirm-button-enabled" : "confirm-button-disabled"}`}
                >
                  {t("button.send")}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Modal>
  );
}
