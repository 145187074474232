import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/Modal";
import modifyImg from "../../assets/icons/modifyDevice.png";
import { deviceActions } from "../../store/deviceSlice";
import { commonActions } from "../../store/commonSlice";
import { getDevices, patchDeviceState } from "../../api/customerDevice";
import ButtonDeviceState from "./ButtonDeviceState";
import LoadingIndicator from "./LoadingIndicator";
import { dialogActions } from "../../store/dialogSlice";

export default function DeviceModificationDialog() {
  const { t } = useTranslation();
  const location = "modify";
  const modificationDialog = useSelector(
    (state) => state.device.modificationDialog
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" || event.key === "Enter") {
        // ESC 키를 누를 때 기본 동작을 차단
        event.preventDefault();
      }
    };

    if (modificationDialog.isOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [modificationDialog.isOpen]);
  const isDialogLoading = useSelector((state) => state.common.isDialogLoading);

  const selectedDialog = useSelector(
    (state) => state.device.modificationDialog
  );
  function classes({ value, onFocus }) {
    return `${value !== "" || onFocus ? "bg-white outline outline-2 outline-primary-400" : "bg-gray-100"} w-full h-[48px] px-[16px] rounded-[10px] focus:outline-primary-400`;
  }
  function handleOnCancel() {
    //dispatch(deviceActions.setIsOpen({ location, isOpen: false }));
    dispatch(deviceActions.resetModification());
  }
  async function handleOnConfirm() {
    const selectedState = checkDeviceState();
    dispatch(commonActions.setIsDialogLoading(true));
    const response = await patchDeviceState({
      deviceState: selectedState,
      id: selectedDialog.id,
    });
    dispatch(commonActions.setIsDialogLoading(false));
    if (response) {
      console.log("기기수정 완료");
      dispatch(deviceActions.resetModification());
      const fetchDevices = async () => {
        dispatch(commonActions.setIsTableLoading(true));
        const response = await getDevices({ isTest: false });
        dispatch(commonActions.setIsTableLoading(false));
        if (response) {
          console.log("Devices 호출 성공", response);
          const data = response.content;
          if (data) {
            dispatch(deviceActions.setDevices(data));
            dispatch(dialogActions.setModifyCompleteIsOpen(true));
            // console.log("저장된 기기들", data);
          } else {
            console.log("기기 없음");
          }
        } else {
          console.log("Devices 호출 실패", response);
        }
      };
      fetchDevices();
    } else {
    }
  }
  function checkDeviceState() {
    const deviceState = {
      ok: "ok",
      failure: "failure",
      dispose: "dispose",
    };
    return deviceState[
      Object.keys(selectedDialog.deviceState).find(
        (key) => selectedDialog.deviceState[key] === true
      )
    ];
  }
  return (
    <Modal
      open={modificationDialog.isOpen}
      className={`modal modal_input w-[610px] `}
    >
      <LoadingIndicator isLoading={isDialogLoading} />
      <div className="flex flex-col h-full ">
        <header className="px-[37px] pt-[28px] border-b-[0.5px] border-gray-400 border-opacity-50 pb-[15.8px]">
          <div className="flex items-center">
            <div className="w-[40px] h-[40px] flex items-center justify-center bg-primary-200 shadow-custom">
              <img src={modifyImg} className="h-[30px] w-[30px]" alt="regist" />
            </div>
            <h2 className="pl-[12px] text-[24px] font-bold">
              {t("device_edit_dialog.title")}
            </h2>
          </div>
        </header>

        <main className="flex-grow h-full flex flex-col px-[53px]">
          <div className="flex justify-center pt-[39.8px] pb-[40px]">
            <div className="relative w-full flex flex-col gap-[8px]">
              <p className="text-primary-700 leading-none">
                {t("device_edit_dialog.model")}
              </p>

              <input
                readOnly
                value={selectedDialog.prodName}
                className={classes({
                  value: selectedDialog.prodName,
                })}
                type="text"
              />
            </div>
          </div>
          <div className="flex gap-[54px]">
            <div className="w-full flex flex-col gap-[8px]">
              <p className="text-primary-700 leading-none">
                {t("device_edit_dialog.reg_number")}
              </p>
              <input
                readOnly
                value={selectedDialog.regCode}
                className={classes({
                  value: selectedDialog.regCode,
                })}
                type="text"
              />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <p className="text-primary-700 leading-none">
                {location === "regist"
                  ? t("regist_device_dialog.device_serial.label")
                  : t("device_edit_dialog.device_serial")}
              </p>
              <input
                readOnly
                value={selectedDialog.serial}
                className={classes({
                  value: selectedDialog.serial,
                })}
                type="text"
              />
            </div>
          </div>
          <div className="h-[40px]"></div>
          <div className="w-full flex flex-col gap-[8px]">
            <p className="text-primary-700 leading-none">
              {t("device_edit_dialog.device_state.label")}
            </p>
            <div className="flex gap-[12px] h-[48px]">
              <ButtonDeviceState
                label={t("device_edit_dialog.device_state.ok")}
                isSelected={selectedDialog.deviceState.ok}
                onClick={() => {
                  dispatch(
                    deviceActions.setModifyDialogData({ identifier: "ok" })
                  );
                }}
              />
              <ButtonDeviceState
                label={t("device_edit_dialog.device_state.failure")}
                isSelected={selectedDialog.deviceState.failure}
                onClick={() => {
                  dispatch(
                    deviceActions.setModifyDialogData({
                      identifier: "failure",
                    })
                  );
                }}
              />
              <ButtonDeviceState
                label={t("device_edit_dialog.device_state.dispose")}
                isSelected={selectedDialog.deviceState.dispose}
                onClick={() => {
                  dispatch(
                    deviceActions.setModifyDialogData({
                      identifier: "dispose",
                    })
                  );
                }}
              />
            </div>
          </div>
          <div className="h-[22px]"></div>
          <div className="button-container">
            <button
              onClick={handleOnCancel}
              className="common-button cancel-button"
            >
              {t("button.cancel")}
            </button>
            <button
              onClick={handleOnConfirm}
              className={`common-button confirm-button confirm-button-enabled`}
            >
              {t("button.modify")}
            </button>
          </div>
        </main>
      </div>
    </Modal>
  );
}
