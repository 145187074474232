import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

const dialogSlice = createSlice({
  name: "dialog",
  initialState: {
    sendComplete: {
      isOpen: false,
    },
    registComplete: {
      isOpen: false,
    },
    modifyComplete: {
      isOpen: false,
    },
    deleteComplete: {
      isOpen: false,
    },
    deleteCheck: {
      isOpen: false,
    },
    changeComplete: {
      isOpen: false,
    },
    logoutCheck: {
      isOpen: false,
    },
    logoutComplete: {
      isOpen: false,
    },
  },
  reducers: {
    setSendCompleteIsOpen(state, action) {
      state.sendComplete.isOpen = action.payload;
    },
    setRegistCompleteIsOpen(state, action) {
      state.registComplete.isOpen = action.payload;
    },
    setModifyCompleteIsOpen(state, action) {
      state.modifyComplete.isOpen = action.payload;
    },
    setDeleteCompleteIsOpen(state, action) {
      state.deleteComplete.isOpen = action.payload;
    },
    setDeleteCheckIsOpen(state, action) {
      state.deleteCheck.isOpen = action.payload;
    },
    setChangeCompleteIsOpen(state, action) {
      state.changeComplete.isOpen = action.payload;
    },
    setLogoutCheckIsOpen(state, action) {
      state.logoutCheck.isOpen = action.payload;
    },
    setLogoutCompleteIsOpen(state, action) {
      state.logoutComplete.isOpen = action.payload;
    },
  },
});

export default dialogSlice.reducer;
export const dialogActions = dialogSlice.actions;
